import React, {useCallback, useEffect, useState} from "react";
import '../dmpList/dmplist.css';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import api from '../../api';
import {debounce} from "lodash";

function DmpHisPageList() {
    const [dmpList, setDmpList] = useState([]); // 현재 표시되는 DMP 리스트
    const [searchTerm, setSearchTerm] = useState(""); // 검색어 상태
    const [listCount, setListCount] = useState(0); // 총 데이터 개수
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지

    const [btnPage, setBtnPage] = useState(1);

    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 개수 (1-10, 11-20 등)

    const navigate = useNavigate();

    // const {DmpNo} = useParams()

    const location = useLocation();
    const { DmpNo } = location.state || {}; // 전달된 id 가져오기

    // DMP 리스트 API 호출 함수
    const fetchData = async (searchTerm = "", page = 1) => {

        const start = (page - 1) * pageSize + 1; // 페이지 시작 위치 계산

        let endpoint = `/api/history/list?DmpNo=${DmpNo}&kw=${searchTerm}&ps=${start}&pl=${pageSize}`
        try {
            const res = await api.get(endpoint, {
                withCredentials: true,
            });
            setDmpList(res.data.items || res.data.item);
            setListCount(res.data.pages.Total); // 총 데이터 개수 설정
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산
        } catch (error) {
            console.error("요청에 실패했습니다.", error);
        }
    };

    // 페이지가 변경되거나 DMP 리스트 조회 방식이 변경될 때 데이터 가져오기
    useEffect(() => {
        fetchData(searchTerm, currentPage);
    }, [currentPage, searchTerm]);

    const dmpRowClick = async (DmpHisNo) => {
        navigate('/dmpManagement/dmpHisPageView', {
            state: { DmpHisNo }, // id를 state로 전달
        });
    };

    // 디바운스된 함수 생성 (300ms)
    // const debouncedFetchData = useCallback(
    //     debounce((value, page) => fetchData(value, page), 300),
    //     []
    // );

    // 검색어 입력 처리
    const handlerInputChange = (event) => {
        const value = event.target.value;

        // 입력된 값의 길이가 14자를 넘으면 alert를 띄우고 값을 초기화
        if (value.length > 14) {
            alert("입력 가능한 최대 길이는 14자입니다.");
            setSearchTerm(""); // 값을 초기화
            event.target.value = ""; // input 필드도 초기화
            return;
        }
        setSearchTerm(value); // 상태 업데이트
        setCurrentPage(1); // 페이지를 초기화
        fetchData(value, 1); // 최신 값을 사용하여 즉시 데이터 가져오기

        // setSearchTerm(value);
        // setCurrentPage(1);
        // debouncedFetchData(value, 1); // 디바운스된 API 호출
    };

    const handerbtn = (pageNumber) => {
        setCurrentPage(pageNumber); // 상태 업데이트
        fetchData(searchTerm, pageNumber); // 최신 값을 사용하여 즉시 데이터 가져오기
    };


    // const handerbtn = (pageNumber, i) => {
    //     setCurrentPage(pageNumber);
    //     setBtnPage(i);
    //
    // }

    // 페이지네이션 렌더링 함수
    const renderPagination = () => {
        const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => handerbtn(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages.map((page) => (
                    <button
                        key={page}
                        className={`pagination-button ${page === currentPage ? "active" : ""}`}
                        onClick={() => handerbtn(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => handerbtn(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    return (
        <>
            <div className="dmplist-container">
                <div className="dmplist-banner-img">
                    <img
                        src={`${process.env.PUBLIC_URL}/sub-introimg02.jpg`}
                        className="dmplist-img02"
                        alt="search Logo"
                    />
                    <div className="banner-text">
                        <span className="intro-subName">데이터 관리 계획(이력페이지)</span>
                    </div>
                    <div className="dmplist-description">
                        <div className="dmplist-description02">
                            <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 관리 계획 > DMP 목록</span>
                        </div>
                    </div>
                </div>
                <div className="dmplist-inputbox">
                    <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                    <input
                        type="text"
                        placeholder="검색어를 입력해주세요."
                        value={searchTerm}
                        onChange={handlerInputChange}
                    />
                </div>
                <div>
                    <div className="btn-container">
                        <div className="history-select">
                            <div>총 {listCount}건</div>
                        </div>
                    </div>
                    <div className="dmplist-start_line"></div>

                    {/* dmpList가 비어있을 경우 메시지를 출력 */}
                    {dmpList.length === 0 ? (
                        <>
                            <div className="noDataList">
                                <h1>이력 DMP가 없습니다.</h1>
                            </div>
                        </>
                    ) : (
                        <>
                            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                <thead>
                                <tr className="dmplist-title">
                                    <th className="obn01">과제번호</th>
                                    <th className="obn02">연차</th>
                                    <th className="obn03">과제명</th>
                                    <th className="obn04">연구 책임자</th>
                                    <th className="obn03">수정자</th>
                                    <th className="obn05">DMP 상태</th>
                                    <th className="obn06">마지막수정일</th>
                                </tr>
                                </thead>
                                <tbody>
                                {dmpList.map((row, index) => (
                                    <tr key={index} className="dmplist-Data">
                                        <td>{row.ProjNo}</td>
                                        <td>{row.ProjAnnl}년차</td>
                                        <td
                                            style={{ cursor: "pointer" }}
                                            onClick={() => dmpRowClick(row.DmpHisNo, row.DmpNo)}
                                        >
                                            {row.ProjNm}
                                        </td>
                                        <td>{row.RsPnEmpNm}</td>
                                        <td>{row.UserNm}</td>
                                        <td>
                                            {(() => {
                                                if (row.SubmitStatus === "0") {
                                                    return "임시저장";
                                                } else if (row.SubmitStatus === "1") {
                                                    return "저장";
                                                } else if (row.SubmitStatus === "2") {
                                                    return "제출";
                                                } else if (row.SubmitStatus === "3") {
                                                    return "검토완료";
                                                } else if (row.SubmitStatus === "4") {
                                                    return "검토완료(의견있음)";
                                                } else {
                                                    return ""; // 기본값
                                                }
                                            })()}
                                        </td>
                                        <td>{row.HisInsDT || row.UpdDt}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            {/* 페이지네이션 추가 */}
                            {renderPagination()}
                        </>
                    )}
                </div>

            </div>
        </>
    );
}

export default DmpHisPageList;
