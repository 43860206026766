import React, { useEffect, useState } from "react";
import './importDataSet.css'
import api from '../api';
import {useDataSetsToastStore, useDataSummaryToastFilterStore, useDataSummaryToastStore} from "../stores/dataSetsStore";

function ImportDataSet({DmpNo}) {
    const [tableData, setTableData] = useState([]);

    // 팝업 창 닫기
    const [isPopupOpen, setIsPopOpen] = useState(true);

    const {setCallToastDataSets} = useDataSetsToastStore();
    // const {setCallSummaryDataSets} = useDataSummaryToastStore();
    const {setCallSummaryFilterDataSets} = useDataSummaryToastFilterStore();


    const closePopup = () => {
        setIsPopOpen(false);
    }



    useEffect(async () => {
        await api.get(`/api/dmp/dataSetListLoad?DmpNo=${DmpNo}`, {withCredentials: true})
            .then((res) => {
                setTableData(res.data);
            })
            .catch(error => {
                console.log("lastDMP의 api를 확인해보세요.", error);
            });
    }, [setTableData]);
    const objCheckHandler = async (id) => {

        const importDataFilter = tableData.filter((item)=> id === item.DtaSetNo);
        setCallSummaryFilterDataSets(importDataFilter);

        // await api.get(`/api/dmp/loadDmpDataSummary?DtaSetNo=${id}&dmpNo=${DmpNo}`, {withCredentials: true})
        await api.get(`/api/data/dtllistload?DtaSetNo=${importDataFilter[0].DtaSetNo}`, {withCredentials: true})
            .then((res) => {
                setCallToastDataSets(res.data);
                // setCallSummaryDataSets(res.data.summary);
            }).
                catch(error => {
                    console.log("데이터 업데이터 api 확인 해주세요.")
            })


        setIsPopOpen(false);
    }


    return (
        <>
            {isPopupOpen && (
                <div className="importData-container">
                    <div className="importData-title01">
                        <div className="importData-context01">
                            <h1>DMP 데이터세트 조회</h1>
                            <img
                                src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                alt="close"
                                onClick={closePopup}
                                style={{ cursor: 'pointer'}} // 클릭 가능하게 커서를 변경합니다.
                            />
                        </div>
                        <div className="importData-context02">
                            <span>※과제가 보이지 않는 경우 DMP 목록에서 작성된 내용이 있는지 확인하세요.</span>
                            <span>원하는 데이터세트를 선택해주세요.</span>
                        </div>
                    </div>
                    <div className="importData-inputbox">
                        <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                        <input type="text" placeholder="과제 데이터를 조회하세요."   />
                    </div>

                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                        <tr className="importData-title">
                            <th>구분</th>
                            <th>선택*필수</th>
                            <th>데이터 타입명</th>
                            <th>데이터 타입 셜명</th>
                            <th>등록여부</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableData.map((row) => (
                            <tr key={row.DtaSetNo} className="importData-Data">
                                <td>
                                    <button onClick={() => objCheckHandler(row.DtaSetNo)}>
                                        {row.DtaSetNm}
                                    </button>
                                </td>
                                <td className="data-cell-width">{row.DtaSetNmSub}</td>
                                <td className="data-cell-width">{row.DtaNm}</td>
                                <td className="data-cell-width">{row.DtaDs}</td>
                                <td>
                                    {row.DmpFlg === 'N' ? '직접 작성' : row.DmpFlg === 'Y' ? 'DMP에서 작성' : ''}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default ImportDataSet;
