import React, { useEffect, useState} from "react";
import api from '../../api';
import Cookies from "js-cookie";
import {useLocation, useNavigate} from "react-router-dom";

function DmpRealDataSend() {
    const [dataList, setDataList] = useState([]);
    const [dataCount, setDataCount] = useState(0); // 수정: 총 데이터 개수 상태값
    const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수

    const location = useLocation();
    const navigate = useNavigate();

    // URL 쿼리 파라미터에서 초기 값 추출
    const queryParams = new URLSearchParams(location.search);
    const [currentPage, setCurrentPage] = useState(Number(queryParams.get("page")) || 1);
    const [searchTerm, setSearchTerm] = useState(queryParams.get("search") || "");

    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 수 (1-10, 11-20 등)

    const fetchData =  (searchTerm = "", page = 1) => {
        try {
            const start = (page - 1) * pageSize + 1; // 페이지 시작 위치 계산
            api.get(`/api/data/datalist?kw=${searchTerm}&ps=${start}&pl=${pageSize}&pg=${'SBM'}`, {
                withCredentials: true
            }).then(res => {
                setDataCount(res.data.pages.Total); // 총 데이터 개수 설정
                setDataList(res.data.item); // 현재 페이지 데이터 목록
                setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산
            });
        } catch (error) {
            console.error("요청에 실패했습니다.", error);
        }
    };

    // 페이지가 변경되거나 DMP 리스트 조회 방식이 변경될 때 데이터 가져오기
    useEffect(() => {
        fetchData(searchTerm, currentPage);
    }, [currentPage, searchTerm]);


    useEffect(() => {
        const params = new URLSearchParams();
        if (searchTerm) params.set("search", searchTerm);
        if (currentPage) params.set("page", currentPage);
        navigate(`?${params.toString()}`, { replace: true }); // URL을 업데이트하되 브라우저 기록을 추가하지 않음
    }, [currentPage, searchTerm, navigate]);

    const handlerInputChange = (event) => {
        const value = event.target.value;

        // 입력된 값의 길이가 14자를 넘으면 alert를 띄우고 값을 초기화
        if (value.length > 14) {
            alert("입력 가능한 최대 길이는 14자입니다.");
            setSearchTerm(""); // 값을 초기화
            event.target.value = ""; // input 필드도 초기화
            return;
        }
        setSearchTerm(value); // 상태 업데이트
        setCurrentPage(1); // 페이지를 초기화
        fetchData(value, 1); // 최신 값을 사용하여 즉시 데이터 가져오기
        // setSearchTerm(value);
        // setCurrentPage(1);
        // setBtnPage(1);
        // debouncedFetchData(value); // 디바운스된 API 호출
    };

    // const handerbtn = (pageNumber, i) => {
    //     setCurrentPage(pageNumber);
    //     setBtnPage(i);
    //
    // }


    const handerbtn = (pageNumber) => {
        setCurrentPage(pageNumber); // 상태 업데이트
        fetchData(searchTerm, pageNumber); // 최신 값을 사용하여 즉시 데이터 가져오기
    };



    const dataSendHandler =  (id) => {
        const csrfToken = Cookies.get('XSRF-TOKEN');
            api.put(`/api/data/datasubmit?DtaSbmSeqNo=${id}`, {}, {
                withCredentials: true,
                headers: {
                    'X-XSRF-TOKEN': csrfToken,
                }
            })
                .then(res => {
                    alert("제출 완료 되었습니다.");

                    window.location.reload();
                    fetchData(searchTerm, currentPage);

                    // 로컬 상태에서 제출된 행 업데이트
                    setDataList((prevDmpsend) =>
                        prevDmpsend.map((row) =>
                            row.DmpNo === id
                                ? { ...row, SubmitStatus: '2' } // 제출 상태를 '2'(제출 완료)로 업데이트
                                : row
                        )
                    );

                }).catch (error => {
            console.error("데이터 제출 중 문제가 발생했습니다", error);
        });
    };


    // 페이지네이션 렌더링 함수
    const renderPagination = () => {
        const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => handerbtn(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages.map((page) => (
                    <button
                        key={page}
                        className={`pagination-button ${page === currentPage ? "active" : ""}`}
                        onClick={() => handerbtn(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => handerbtn(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    const submitHandler02 = () => {
        alert("현재 제출 상태입니다.");
    }
    const completeHandler = () => {
        alert("현재 검토완료 상태입니다.");
    }
    const completeHandlerOpinion = () => {
        alert("현재 검토완료(의견있음) 상태입니다.");
    }

    const sendMoveModify = (DtaSbmSeqNo) => {
        navigate('/dmpRealData/dmpRealDataView', {
            state: { DtaSbmSeqNo }, // id를 state로 전달
        });
    }


    return (
        <>
            <div className="dmpReallist-container">
                <div className="dmpReallist-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/sub-introimg03.jpg`} className="dmpReallist-img02" alt="search Logo" />
                    <div className="banner-Realtext">
                        <span className="intro-subName">데이터 제출</span>
                    </div>
                    <div className="dmpReallist-description">
                        <div className="dmpReallist-description02">
                            <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 > 데이터 제출</span>
                        </div>
                    </div>
                </div>
                <div className="dmpReallist-inputbox">
                    <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                    <input type="text" placeholder="검색어를 입력해주세요."
                           value={searchTerm}
                           onChange={handlerInputChange}

                    />
                </div>
                <div>
                    <div className="btn-Realcontainer">
                        <div>총 {dataCount}건</div>
                    </div>
                    <div className="dmpReallist-start_line"></div>
                    {dataList.length === 0 ? (
                        <>
                            <div className="noDataList">
                                <h1>이력 데이터가 없습니다.</h1>
                            </div>
                        </>
                    ) : (
                        <>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                <tr className="dmpReallist-title">
                                    <th>과제번호</th>
                                    <th>데이터셋/ 설명</th>
                                    <th>연구 책임자</th>
                                    <th>제출 상태</th>
                                </tr>
                                </thead>
                                <tbody>
                                {dataList
                                    .map((row) => (
                                        <tr key={row.DtaSbmSeqNo} className="dmpReallist-Data">
                                            <td>{row.ProjNo}</td>
                                            <td onClick={() => sendMoveModify(row.DtaSbmSeqNo)}>{row.DtaSetNm} / {row.DtaDs}</td>
                                            <td>{row.RspnEmpNm}</td>
                                            <td>
                                                {row.SubmitStatus === '1' ? (
                                                    <button className="dmpsend-submit01" onClick={() => dataSendHandler(row.DtaSbmSeqNo)}>데이터 제출하기</button>
                                                ) : row.SubmitStatus === '2' ? (
                                                    <button className="dmpsend-submit02" onClick={submitHandler02}>제출</button>
                                                ) : row.SubmitStatus === '3' ? (
                                                    <button className="dmpsend-complete" onClick={completeHandler}>검토완료</button>
                                                ) : row.SubmitStatus === '4' ? (
                                                    <button className="dmpsend-complete-opinion" onClick={completeHandlerOpinion}>검토완료(의견있음)</button>
                                                ) : null }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* 페이지네이션 추가 */}
                            {renderPagination()}
                        </>
                        )}
                </div>
            </div>
        </>
    );
}

export default DmpRealDataSend;
