import React, {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import api from "../api";
import Cookies from 'js-cookie';

function BoardView() {

    const navigate = useNavigate();
    const location = useLocation();
    const [files, setFiles] = useState([]);
    const [showCallImgData, setShowCallImgData] = useState([]);

    // URLSearchParams 객체를 사용하여 쿼리 파라미터 가져오기
    const queryParams = new URLSearchParams(location.search);
    const boardSeq = queryParams.get('BoardSeq'); // URL에서 BoardSeq 값 추출
    const seq = queryParams.get('seq'); // URL에서 seq 값 추출

    //state에서 가져온 값


    const [detailValue, setDetailValue] = useState({});
    const [boardFile, setBoardFile] = useState([]);
    const [, setBoardName] = useState({});
    const [modifyTitle, setModifyTitle] = useState("");
    const [modifyCn, setModifyCn] = useState("");

    const [boardUserNo, setBoardUserNo] = useState("");

    const [authCheck, setAuthCheck] = useState(null); // "Y" 또는 "N"
    const [error, setError] = useState(null); // 에러 메시지 저장


    const category =
        boardSeq === "1" ? "공지사항" :
            boardSeq === "2" ? "교육/세미나" :
                boardSeq === "3" ? "법령/정책자료" :
                    "자료실";



    useEffect(() => {
        // 게시글 상세 정보 호출
        api.get(`/api/board/detail?BoardSeq=${boardSeq}&Seq=${seq}`).then(res => {
            setDetailValue(res.data.detail);
            setBoardFile(res.data.file);
            setBoardUserNo(res.data.detail.UserNo);
            setModifyTitle(res.data.detail.Title);  // 제목 설정
            setModifyCn(res.data.detail.Contents);  // 본문 설정
        });

        // 게시판 이름 정보 호출
        api.get(`/api/board?BoardSeq=${boardSeq}`).then(res => {
            setBoardName(res.data);
        });
    }, [boardSeq, seq]);



    // useEffect(() => {
    //     const csrfToken = Cookies.get("XSRF-TOKEN"); // CSRF 토큰을 가져옴
    //     api.get("/api/auth/ckeckuserno", {
    //
    //         params: { UserNo: boardUserNo },
    //         withCredentials: true,
    //         headers: {
    //             'X-XSRF-TOKEN': csrfToken,
    //         }
    //     }).then(res => {
    //         setAuthcheck(res.data);
    //     })
    // },[boardUserNo])


    useEffect(() => {
        const checkAdminStatus = async () => {

            if (error && error.includes("세션이 유효하지 않습니다")) {
                return navigate("/login"); // 로그인 페이지로 리다이렉트;
            }

            try {
                const res = await api.get("/api/auth/ckeckadmin", {
                    withCredentials: true,
                });

                if (res.status === 200) {
                    setAuthCheck(res.data); // "Y"
                    // alert("관리자 권한이 확인되었습니다.");
                } else {
                    setAuthCheck("N");
                    // alert("일반 사용자로 확인되었습니다.");
                }
            } catch (error) {
                if (error.response) {
                    // 서버 응답이 있는 경우 (401, 403 등)
                    if (error.response.status === 401) {
                        setError("세션이 유효하지 않습니다. 다시 로그인해주세요.");
                        // alert("로그인이 필요합니다. 로그인 페이지로 이동합니다.");
                        // navigate("/login"); // 로그인 페이지로 리다이렉트
                    } else if (error.response.status === 403) {
                        // alert("관리자 권한이 없습니다.");
                    }
                    setAuthCheck("N"); // 관리자 아님
                } else {
                    // 서버 응답이 없는 경우 (네트워크 오류 등)
                    alert("서버와 통신 중 문제가 발생했습니다.");
                }
            }
        };

        checkAdminStatus();
    }, []);



    useEffect(() => {
        const updatedFileList = boardFile?.map(item => ({
            ...item,
            DtaFilePath: item.FilePath || null,
        }));
        setShowCallImgData(updatedFileList);
    }, [boardFile]);

    const handleFileChange = (event) => {
        if (Array.isArray(showCallImgData) && showCallImgData.length > 0) {
            // 기존 파일 목록에 새로운 파일들을 추가
            setShowCallImgData((prev) => [...prev, ...Array.from(event.target.files)]);
        } else {
            setFiles((prev)=> [...prev, ...Array.from(event.target.files)]);
        }
    };

    const handleUpload = async () => {
        if (files.length === 0 && showCallImgData.length === 0) {
            alert('파일을 먼저 선택해주세요.');
            return;
        }

        const formData = new FormData();

        // showCallImgData.forEach((file) => {
        //     formData.append('files', file);
        // });
        // formData.append('ct', "Board");

        const fileList = (files && files.length > 0) ? files : showCallImgData;

        if (fileList && fileList.length > 0) {
            fileList.forEach((file) => {
                formData.append('files', file);
            });
        }
        formData.append('ct', "Board");

        const csrfToken = Cookies.get('XSRF-TOKEN');

        try {
            const response = await api.post('/api/file/uploadfiles', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-XSRF-TOKEN': csrfToken,
                },
                withCredentials: true
            });
            alert('업로드 완료 되었습니다.');


            // type이 있는 파일들을 showCallImgData에서 제거
            const updatedShowCallImgData = showCallImgData.filter((item) => !item.type);

            // response.data에 있는 파일을 새로운 형식으로 변환하여 추가
            const newFiles = response.data.map((file) => ({
                ...file,
                Fstatus: "save",
                BoardListSeq : boardFile.BoardListSeq,
            }));


            // 상태 업데이트: type이 없는 기존 파일들 + 새로운 파일들
            setShowCallImgData([...updatedShowCallImgData, ...newFiles]);

        } catch (error) {
            console.error('이미 업로드가 완료된 파일 입니다.', error);
            alert('이미 업로드가 완료된 파일 입니다.');
        }
    };

    useEffect(() => {
    }, [showCallImgData]);

    // const closeFile = (indexRemoveFile) => {
    //     setShowCallImgData((prevFiles) =>
    //         prevFiles.map((item, index) => {
    //             if (index === indexRemoveFile) {
    //                 return {
    //                     ...item,
    //                     Fstatus: "delete", // 삭제 상태 추가
    //                     BoardListSeq : boardFile.BoardListSeq,
    //                 };
    //             }
    //             return item;
    //         })
    //     );
    //     alert("삭제되었습니다.")
    // };

    //삭제 이후 데이터 제출 렌더링
    useEffect(() => {
    }, [showCallImgData]);

    const boardModifyHandler = async () => {
        navigate(`/board/boardDetail?BoardSeq=${boardSeq}&seq=${seq}`);
    };


    const boardRemoveHandler = async () => {
        try {
            const csrfToken = Cookies.get('XSRF-TOKEN'); // CSRF 토큰을 쿠키에서 가져오기

            const res = await api.delete(`/api/board/board/${seq}`, {
                headers: {
                    'X-XSRF-TOKEN': csrfToken, // CSRF 토큰 헤더에 추가
                },
                withCredentials: true, // 쿠키 사용 시 필요
            });

            if (res.data === 'Y') {
                alert("해당 게시글이 삭제되었습니다.");
                if (boardSeq === '1') {
                    navigate(`/board`);  // 저장 후 해당 BoardSeq 페이지로 이동
                } else {
                    navigate(`/dataRoom`);  // 저장 후 해당 BoardSeq 페이지로 이동
                }
            }
        } catch (err) {
            alert("삭제 중 오류가 발생했습니다.");
        }
    };


    const openPreviewHandler = async (fn, originName, dir, fileSeqNumber) => {

        try {
            // API 호출
            const response = await api.get(`/api/file/downloadFile`, {
                params: {
                    fn: fn,
                    originName: originName,
                    dir: dir,
                    FileSeqNo: fileSeqNumber
                },
                withCredentials: true,
                responseType: 'blob', // Blob으로 응답 받음
            });

            // 파일 다운로드 성공 처리
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', originName); // originName을 파일 이름으로 설정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // 다운로드 완료 후 링크 제거

            alert('다운로드 성공 했습니다.');
        } catch (err) {
            console.error('파일 다운로드에 실패했습니다.', err);
            alert('파일을 다운로드할 수 없습니다.');
        }
    };

    return (
        <>
            <div className="dmpRealData-banner-container">
                <div className="dmpRealData-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/sub-introimg01.jpg`} className="dmpReallist-img02" alt="search Logo" />
                    <div className="banner-Realtext">
                        <span className="intro-subName">새로운 소식을 알려드립니다.</span>
                    </div>
                    <div className="dmpRealData-description">
                        <div className="dmpRealData-description02">
                            <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 소개> 공지사항</span>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="RealData-management-title">
                        <h1>게시글 상세</h1>
                        <div>
                            <span className="required_color">*</span>
                            <span>필수 항목</span>
                        </div>
                    </div>
                    <div className="under_line"></div>
                    <div className="board-category">
                        <div>
                            <span className="RealData-info">카테고리</span>
                            <span className="required_color">*</span>
                        </div>
                        <select className="category-select" value={category} disabled>
                            <option value="공지사항">공지사항</option>
                            <option value="교육/세미나">교육/세미나</option>
                            <option value="법령/정책자료">법령/정책자료</option>
                            <option value="자료실">자료실</option>

                        </select>
                    </div>
                    <div className="start_line"></div>
                    <div className="RealData-object-info">
                        <div className="RealData-object-info-title" style={{width: '140px'}}>
                            <span className="RealData-info">제목</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="RealData-task-title">
                            <input
                                type="text"
                                className="edit--box"
                                readOnly
                                style={{width:'700px'}}
                                value={modifyTitle}
                                onChange={(e) => setModifyTitle(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="dataCreate-container">
                        <div className="dataCreate-title">
                            <span>본문</span>
                            <span className="required_color">*</span>
                        </div>
                        <textarea
                            className="edit--box"
                            readOnly
                            style={{width: '900px', height: '400px', padding: '10px', fontSize: '16px'}}
                            value={modifyCn}
                            onChange={(e) => setModifyCn(e.target.value)}
                        />
                    </div>
                    <div className="start_line"></div>
                    <div className="RealDataSend-title">
                        <div className="boardData-send">
                            <span>첨부파일</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="modify-sendFile-container-preview">
                            {Array.isArray(files) && files.length > 0 ? (
                                files.map((item, index) => (
                                    <>
                                        <div className="fileName" key={index}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                                alt="close"
                                                // onClick={() => closeFile(index)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                            <div>
                                                <p>{item.name}</p> {/* 파일의 이름을 표시합니다 */}
                                            </div>
                                        </div>
                                        <div className="RealData-btnContainer">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/btn_preview.png`}
                                                alt="preview"
                                                onClick={() => openPreviewHandler(item.SavFleNm, item.OrgFileNm, item.FilePath, item.BoardListFileSeq)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                    </>
                                ))
                            ) : (
                                Array.isArray(showCallImgData) && showCallImgData.length > 0 &&
                                showCallImgData.map((item, index) => {
                                    if (item.Fstatus === "delete") {
                                        // 삭제된 항목은 렌더링하지 않음
                                        return null;
                                    } else if (item.Fstatus === "save") {
                                        // 저장된 항목은 저장된 상태로 렌더링
                                        return (
                                            <>
                                                <div className="fileName" key={index}>

                                                    <div>
                                                        <p>{item.OriginalFileName}</p> {/* 파일의 이름을 표시합니다 */}
                                                    </div>
                                                </div>
                                                <div className="RealData-btnContainer">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/btn_preview.png`}
                                                        alt="preview"
                                                        onClick={() => openPreviewHandler(item.SavFleNm, item.OrgFileNm, item.FilePath, item.BoardListFileSeq)}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </div>
                                            </>
                                        );
                                    } else {
                                        // "Fstatus" 값이 없거나 다른 경우 기본 렌더링
                                        return (
                                          <>
                                              <div className='preview-img'>
                                                  <div className="fileName" key={index}>

                                                      <div>
                                                          <p>{item.OrgFileNm || item.name}</p>
                                                      </div>
                                                  </div>
                                                  <div className="RealData-btnContainer">
                                                      <img
                                                          src={`${process.env.PUBLIC_URL}/btn_preview.png`}
                                                          alt="preview"
                                                          onClick={() => openPreviewHandler(item.SavFleNm, item.OrgFileNm, item.FilePath, item.BoardListFileSeq)}
                                                          style={{ cursor: 'pointer' }}
                                                      />
                                                  </div>
                                              </div>
                                          </>
                                        );
                                    }
                                })
                            )}
                        </div>

                    </div>
                </section>

                <div className="dmpRealData-banner-container">
                    {/* 기존 코드 생략 */}
                    <div className="RealData-save-btn-container">
                        <button id="dmp_list" onClick={() => navigate(-1)}>
                            목록
                        </button>
                        {authCheck === "Y" && (
                            <>
                                <button id="save-btn" onClick={boardModifyHandler}>
                                    수정
                                </button>
                                <button id="save-btn" onClick={boardRemoveHandler}>
                                    삭제
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default BoardView;
