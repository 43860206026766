import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import api from "../api";
import Cookies from 'js-cookie';
import ProgressModal from "../progressModal/progressModal";

function BoardWrite() {

    const [files, setFiles] = useState([]);
    const [uploadImgFile, setUploadImgFile] = useState([]);
    const [sendImgFile, setSendImgFile] = useState([]);
    const [boardtitle, setBoardtitle] = useState("");
    const [boardcn, setBoardcn] = useState("");

    const [progress, setProgress] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [fileNames, setFileNames] = useState([]);

    const navigate = useNavigate();
    const { BoardSeq } = useParams();  // URL에서 BoardSeq를 가져옴

    const [category, setCategory] = useState(
        BoardSeq === "1" ? "공지사항" :
            BoardSeq === "2" ? "교육/세미나" :
                BoardSeq === "3" ? "법령/정책자료" :
                    "자료실"
    );

    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        setCategory(selectedCategory);

        const boardSeqMapping = {
            "공지사항": "1",
            "교육/세미나": "2",
            "법령/정책자료": "3",
            "자료실": "4"
        };

        // 선택된 카테고리에 맞는 새로운 BoardSeq 값으로 URL 변경
        const newBoardSeq = boardSeqMapping[selectedCategory];
        navigate(`/board/write/${newBoardSeq}`);
    };

    // 파일 업로드 처리 함수
    const handleUpload = async (file, index) => {
        if (files.length === 0) {
            alert('파일을 먼저 선택해주세요.');
            return;
        }

        setProgress(0); // 초기 진행률
        setIsCompleted(false); // 완료 상태 초기화
        setIsModalOpen(true); // 모달 열기

        // 이미 업로드된 파일 필터링
        const newFiles = files.filter((file) =>
            !uploadImgFile.includes(file.name) // 파일 이름으로 업로드 여부 확인
        );

        if (newFiles.length === 0) {
            alert('같은 이름의 파일은 업로드가 불가능 합니다.');
            return;
        }

        const formData = new FormData();
        newFiles.forEach((file) => {
            formData.append('files', file);
        });
        formData.append('ct', "Board");

        const csrfToken = Cookies.get('XSRF-TOKEN');

        try {
            const response = await api.post('/api/file/uploadfiles', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-XSRF-TOKEN': csrfToken,
                },
                withCredentials: true,
                onUploadProgress: (progressEvent) => {
                    const total = progressEvent.total || 0;
                    const loaded = progressEvent.loaded || 0;
                    const percentage = Math.round((loaded / total) * 100);
                    setProgress(percentage); // 진행률 업데이트
                },
            });


            // 성공적으로 업로드된 파일 이름 추가
            setUploadImgFile((prev) => [...prev, ...newFiles.map(file => file.name)]);

            // 서버에서 받은 파일 정보 저장
            setSendImgFile((prev) => [...prev, ...response.data]);
            setIsCompleted(true); // 완료 상태 설정
        } catch (error) {
            console.error('파일 업로드에 실패했습니다.', error);
            alert('파일이 업로드 되지 않았습니다.');
        }finally {
            setTimeout(() => {
                setIsModalOpen(false); // 모달 닫기
                setProgress(0); // 초기화
            }, 500); // 2초 후 모달 닫기
        }
    };

    // 파일 삭제 처리
    const closeFile = (indexRemoveFile) => {
        setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexRemoveFile));

        const removeUploadFile = sendImgFile[indexRemoveFile]?.SavFleNm;
        const csrfToken = Cookies.get('XSRF-TOKEN');
        if (removeUploadFile) {
            api.post(`/api/file/deleteFile`, { SaveFile: removeUploadFile }, {
                headers: {
                    'X-XSRF-TOKEN': csrfToken,
                }
            })
                .then(() => alert("파일 삭제가 완료되었습니다."))
                .catch((error) => console.error("파일 삭제 에러:", error));
        }
    };

    // 파일 추가 처리
    const handleFileChange = (event) => {
        setFiles((prev) => [...prev, ...Array.from(event.target.files)]);
    };

    // 게시물 저장 처리
    const newBoardSave = async () => {
        if (!boardtitle || !boardcn) {
            alert("제목과 내용을 입력해주세요.");
            return;
        }

        const boardData = {
            BoardList: {
                BoardSeq: BoardSeq,  // URL에서 가져온 BoardSeq 값으로 설정
                Title: boardtitle,
                Contents: boardcn,
                // UserNo: "작성자 번호", // 필요 시 적절히 변경
                // UserNm: "작성자 이름",  // 필요 시 적절히 변경
                ReadCnt: 0,
                DelFlag: "N"
            },
            BoardListFile: sendImgFile.map(file => ({
                OrgFileNm: file.OriginalFileName,
                SavFleNm: file.SaveFile,
                FilePath: file.FilePath,
                FileSize: file.Size,
            }))
        };

        try {
            const csrfToken = Cookies.get('XSRF-TOKEN');
            await api.post('/api/board/save', boardData, {
                withCredentials: true,
                headers: {
                    'X-XSRF-TOKEN': csrfToken,
                }
            });
            alert('신규 게시물이 성공적으로 저장되었습니다.');

            if(BoardSeq === '1') {
                navigate(`/board`);  // 저장 후 해당 BoardSeq 페이지로 이동
            }
            else {
                navigate(`/dataRoom`);  // 저장 후 해당 BoardSeq 페이지로 이동
            }
        } catch (err) {
            console.error("요청에 실패했습니다.", err);
            alert('게시물 저장 중 에러가 발생했습니다.');
        }
    };
    const newCancel = () => {
        navigate(`/board`);  // 저장 후 해당 BoardSeq 페이지로 이동
    }

    return (
        <>
            <div className="dmpRealData-banner-container">
                <div className="dmpRealData-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/sub-introimg01.jpg`} className="dmpReallist-img02" alt="search Logo" />
                    <div className="RealDatabanner-text">
                        <span className="intro-subName">새로운 소식을 알려드립니다.</span>
                    </div>
                    <div className="dmpRealData-description">
                        <div className="dmpRealData-description02">
                            <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 소개> 공지사항 등록</span>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="RealData-management-title">
                        <h1>공지사항 등록</h1>
                        <div>
                            <span className="required_color">*</span>
                            <span>필수 항목</span>
                        </div>
                    </div>
                    <div className="under_line"></div>
                    <div className="board-category">
                        <div>
                            <span className="RealData-info">카테고리</span>
                            <span className="required_color">*</span>
                        </div>
                        <select className="category-select" value={category} onChange={handleCategoryChange}>
                            <option value="공지사항">공지사항</option>
                            <option value="교육/세미나">교육/세미나</option>
                            <option value="법령/정책자료">법령/정책자료</option>
                            <option value="자료실">자료실</option>

                        </select>
                    </div>
                    <div className="start_line"></div>
                    <div className="board-write-title">
                        <div>
                            <span className="RealData-info">제목</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="RealData-task-title">
                            <input type="text" className="edit--box"
                                   onChange={(e) => setBoardtitle(e.target.value)}
                                   style={{width:'700px'}} placeholder="제목을 입력해주세요." />
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="dataCreate-container">
                        <div className="dataCreate-title">
                            <span>본문</span>
                            <span className="required_color">*</span>
                        </div>
                        <textarea
                            type="text"
                            className="edit--box"
                            onChange={(e) => setBoardcn(e.target.value)}
                            style={{
                                width: '900px',
                                height: '400px',
                                padding: '10px',
                                fontSize: '16px',
                                lineHeight: '1.6',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                backgroundColor: '#f9f9f9',
                                whiteSpace: 'pre-wrap',
                            }}
                            placeholder="본문을 작성해주세요."
                        />
                    </div>
                    <div className="start_line"></div>
                    <div className="RealDataSend-title">
                        <div className="boardData-send">
                            <span>첨부 파일</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="RealData-sendFile-container">
                            {Array.isArray(files) && files.length > 0 && (
                            <>
                                    {files.map((item, index) => (
                                        <div className="creatFile-upload" key={index}>
                                            <div className="fileName">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                                    alt="close"
                                                    onClick={() => closeFile(index)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <div>
                                                    <p>{item.name}</p> {/* 파일의 이름을 표시합니다 */}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                <div className="RealData-btnContainer">
                                    <button
                                        className="RealData-save-button"
                                        onClick={() => handleUpload(files)}>
                                        업로드
                                    </button>
                                    <ProgressModal
                                        isOpen={isModalOpen}
                                        progress={progress}
                                        fileNames={fileNames}
                                        isCompleted={isCompleted}
                                    />
                                </div>
                            </>
                            )}
                            <div>
                                <div className="research-method-title">
                                    <label htmlFor="fileDiagram">
                                        <img id="imgDiagram" src={`${process.env.PUBLIC_URL}/btn_file_select.png`} alt="Diagram" />
                                    </label>
                                </div>
                                <input type="file" id="fileDiagram" name="dataSubmitFiles" multiple onChange={handleFileChange} className="search-input" placeholder="파일 이름" />
                            </div>
                        </div>
                    </div>
                </section>

                <div className="RealData-save-btn-container">
                    <button id="dmp_list" onClick={newCancel}>
                        취소
                    </button>
                    <button id="save-btn" onClick={newBoardSave}>
                        저장
                    </button>
                </div>
            </div>
        </>
    );
}

export default BoardWrite;
