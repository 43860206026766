import {create} from 'zustand';



const useDataSetsToastStore = create(set => ({
    callToastDataSets: [],
    setCallToastDataSets: (newCallToastDataSets) => set({
        callToastDataSets: newCallToastDataSets
    }),
    resetDataSummarySets: () => set({
        callToastDataSets: []  // 상태 초기화 (빈 배열로 설정)
    })
}))

// 상태 초기화 함수


const useDataSummaryToastStore = create(set => ({
        callSummaryDataSets:[],
        setCallSummaryDataSets: (newCallSummaryDataSets) => set({
            callSummaryDataSets: newCallSummaryDataSets
        })
    }
))

const useDataSummaryToastFilterStore = create((set) => ({
    // 초기 상태
    callSummaryFilterDataSets: [],

    // 상태 업데이트 함수
    setCallSummaryFilterDataSets: (newCallSummaryFilterDataSets) => {
        set({ callSummaryFilterDataSets: newCallSummaryFilterDataSets });
    },

    // 상태 초기화 함수
    resetRegisterDataSummarySets: () => {
        set({ callSummaryFilterDataSets: [] }); // 빈 배열로 초기화
    }
}));


// const useDataSummaryToastFilterStore = create(set => ({
//         callSummaryFilterDataSets:[],
//         setCallSummaryFilterDataSets: (newCallSummaryFilterDataSets) => set({
//             callSummaryFilterDataSets: newCallSummaryFilterDataSets
//         }),
//
//         // 상태 초기화 함수
//         resetRegisterDataSummarySets: () => set({
//             callSummaryFilterDataSets: []  // 상태 초기화 (빈 배열로 설정)
//         })
//     }
// ))

//home 페이지에서 검색어의 입력 데이터를 전역으로 관리

const useSearchHomeStore = create((set) => ({
    searchHomeData: '',
    setSearchHomeData: (searchHomeData) => set({ searchHomeData }),

    // 상태 초기화 함수
    resetSearchHomeData: () => set({
        searchHomeData: ''  // 상태 초기화 (빈 배열로 설정)
    })
}));


export {useDataSetsToastStore, useSearchHomeStore, useDataSummaryToastStore, useDataSummaryToastFilterStore}
