import React, { useState, useEffect } from 'react';
import './login.css';
import api from '../api';
import { useNavigate } from "react-router-dom";
import useLoginAuthStore from "../stores/loginAuth";

const LoginPage = () => {
    const [userid, setUserid] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const { setUserId, setIsAdmin, setUserRole } = useLoginAuthStore((state) => ({
        setUserId: state.setUserId,
        setIsAdmin: state.setIsAdmin,
        setUserRole: state.setUserRole,
    }));

    // 로컬 스토리지 값 정리
    useEffect(() => {
        const handleBeforeUnload = () => {
            sessionStorage.removeItem('loginAuthStore');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    // 로그인 처리 함수
    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await api.post(
                `/api/auth/login`,
                {
                    UserId: userid,
                    UserPw: password,
                },
                {
                    withCredentials: true,
                    timeout: 3000,
                }
            );

            const data = response.data;

            // 사용자 역할 및 상태 설정
            setUserId(userid);

            if (data === 'admin') {
                setIsAdmin(true);
                setUserRole('admin');
                navigate('/'); // 관리자 전용 페이지로 이동
            } else if (data === 'Y') {
                setIsAdmin(false);
                setUserRole('user');
                navigate('/'); // 일반 사용자 메인 페이지로 이동
            } else {
                alert("에러가 발생했습니다. 서버 에러입니다.");
            }
        } catch (error) {
            // API 통신 에러 처리
            if (error.response) {
                const { status, data } = error.response;
                if (status === 400) {
                    alert(data); // BAD_REQUEST
                } else if (status === 401) {
                    alert(data); // UNAUTHORIZED
                } else if( status === 500) {
                    alert(data)
                }
                else {
                    alert(data || "알 수 없는 에러가 발생했습니다.");
                }
            } else if (error.code === 'ECONNABORTED') {
                // 네트워크 타임아웃 처리
                alert("요청이 시간이 초과되었습니다. 네트워크 상태를 확인하거나 다시 시도하세요.");
            } else {
                // 기타 에러 처리
                alert("로그인 요청 중 서버 에러가 발생했습니다.");
            }

            console.error('로그인 요청에 실패했습니다.', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div className="login-container">
            <div className="login-logo">
                <img src={`${process.env.PUBLIC_URL}/CI_KFRI.png`} alt="KFRI Logo" />
            </div>

            <div className="login-line"></div>

            <form className="login-form" onSubmit={handleLogin}>
                <div className="form-group">
                    <label htmlFor="username">아이디</label>
                    <input
                        type="text"
                        id="username"
                        value={userid}
                        onChange={(e) => setUserid(e.target.value)}
                        placeholder="아이디를 입력하세요"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="password">비밀번호</label>
                    <div className="password-input">
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="비밀번호를 입력하세요"
                            required
                        />
                    </div>
                </div>
                <button type="submit" className="login-button">
                    로그인
                </button>
            </form>
        </div>
    );
};

export default LoginPage;
