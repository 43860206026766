import React, { useEffect, useState } from "react";
import './lastDmpShow.css';
import api from '../api';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {useLastDataStore, useTaskDataStore} from "../stores/lastDmpData";

function LastDmpShow({ setShowHistory }) {

    // const [, setObjnumber] = useState("");
    // const [, setLeaderName] = useState("");
    // const [, setObjDate] = useState("");

    const [tableData, setTableData] = useState([]);
    const [isPopupOpen, setIsPopOpen] = useState(true);

    // const [isTempDmp, setIsTempDmp] = useState(false); // true: 임시저장 DMP, false: 지난 DMP

    //검색에 필요한 상태값 변환
    const [searchValue, setSearchValue] = useState("");

    const [lastOriginalList, setLastOriginalList] = useState([]);

    const { setLastDataSets } = useLastDataStore();
    //과제선택
    const { setTaskDataSets, taskdataSets} = useTaskDataStore();

    const closePopup = () => {
        setIsPopOpen(false);
    }


    const lastDmpStartYear =  new Date(taskdataSets[0]?.ProjDateSt).getFullYear();
    const lastDmpEndYear = new Date(taskdataSets[0]?.ProjDateEd).getFullYear();

    //년도 자르기
    const generateProductionPlans = () => {
        const years = [];
        for (let i = lastDmpStartYear; i <= lastDmpEndYear; i++) {
            years.push(i);
        }
        return years;
    };

    const lastYearParams = generateProductionPlans().map(year => `&PrdYear=${year}`).join('');

    useEffect(() => {
        const tmpYn = setShowHistory ? 'Y' : 'N';
        api.get(`/api/dmp/dmpload?TmpYn=${tmpYn}`, { withCredentials: true })
            .then((res) => {
                setTableData(res.data);
                setLastOriginalList(res.data);
            })
            .catch(error => {
                console.log("lastDMP의 api를 확인해보세요.", error);
            });
    }, [setShowHistory]);

    const filterHandler = (e) => {
        const value = e.target.value;

        setSearchValue(value);

        const objFilter = lastOriginalList.filter((item) => item.ProjNo.toLowerCase().includes(value.toLowerCase()))
        setTableData(objFilter);


    }

    const PreviewHandler = async (id) => {
        await api.get(`/api/dmp/loaddmpall?dmpNo=${id}${lastYearParams}`, { withCredentials: true })
            .then((res) => {
                setLastDataSets(res.data);
                // setTaskDataSets("");
            })
            .catch(error => {
                console.log("공개 관리 데이터 api 확인 바랍니다.")
            });

        setIsPopOpen(false);
    }

    const downloadPdf = (name, id) => {

        api.get(`/api/hwp/${name}?DmpNo=${id}`, {
            withCredentials: true,
            responseType: 'blob'  // 응답을 blob으로 받기 위해 설정
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to download file, status code: ' + res.status);
                }
                return res.data;  // blob 데이터
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${name}.hwp`; // 파일명
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                // setDownloadedRow(id);
            })
            .catch(error => {
                console.error('문서 다운로드에 실패했습니다.', error);
                alert("문서 다운로드가 불가능합니다. 담당자를 통해 확인해주세요.")

            });
    };

    if (!isPopupOpen) return null;


    return (
        <div className="last-dmp-container">
            <div className="lastDmp-wrapper">
                <div className="last-dmp-title01">
                    <h1>{setShowHistory ? "임시저장 정보 불러와서 작성하기" : "지난 DMP 정보 불러와서 작성하기"}</h1>
                    <img
                        src={`${process.env.PUBLIC_URL}/btn_close.png`}
                        alt="close"
                        onClick={closePopup}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
                <div className="last-dmp-inputbox">
                    <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                    <input type="text"
                           value={searchValue}
                           onChange={filterHandler}
                           placeholder= {setShowHistory ? "임시저장 정보를 검색해주세요." : "지난 DMP 정보를 검색해주세요." } />
                </div>

                {/*<div className="last-dmp-toggle-buttons">*/}
                {/*    <button*/}
                {/*        className={`toggle-button ${isTempDmp ? "active" : ""}`}*/}
                {/*        onClick={() => setIsTempDmp(true)}*/}
                {/*    >*/}
                {/*        임시저장 DMP 불러오기*/}
                {/*    </button>*/}
                {/*    <button*/}
                {/*        className={`toggle-button ${!isTempDmp ? "active" : ""}`}*/}
                {/*        onClick={() => setIsTempDmp(false)}*/}
                {/*    >*/}
                {/*        지난 DMP 불러오기*/}
                {/*    </button>*/}
                {/*</div>*/}

                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                    <tr className="last-dmp-title">
                        <th>과제번호</th>
                        <th>과제이름</th>
                        <th>연구 책임자</th>
                        <th>작성일자</th>
                        <th>DMP 보기</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.map((row, index) => (
                        <tr key={index} className="last-dmp-Data">
                            <td>
                                <button type="button" onClick={() => PreviewHandler(row.DmpNo)}>
                                    {row.ProjNo}
                                </button>
                            </td>
                            <td className="auth-user-cell">{row.ProjNm}</td>
                            <td>{row.RspnEmpNm}</td>
                            <td>{row.UpdDt}</td>
                            <td>
                                <button
                                    type="button"
                                    id="content-container"
                                    onClick={() => downloadPdf(row.ProjNm, row.DmpNo)} // 화살표 함수로 수정
                                >
                                    {row.BtnDMP}
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default LastDmpShow;
