import React, { useCallback, useEffect, useState } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import "./dataSubmitStatus.css";
import api from "../../api";
import { debounce } from "lodash";
import {useSearchHomeStore} from "../../stores/dataSetsStore";

function DataSubmitStatus() {
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
    const [listCount, setListCount] = useState(0); // 총 데이터 개수
    const [cntSave, setCntSave] = useState(0);
    const [cntSubmit, setCntSubmit] = useState(0);
    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
    const [btnPage, setBtnPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState(""); // 검색어 상태
    const [selectedYear, setSelectedYear] = useState(""); // 선택된 년도
    const [years, setYears] = useState([]); // 연도 리스트
    const [totalCnt1, setTotalCnt1] = useState([])
    const [totalCnt2, setTotalCnt2] = useState([])

    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 개수 (1-10, 11-20 등)
    const currentYear = new Date().getFullYear();

    const adminMenu = [
        { 'id': 1, 'name': "공개시기 관리(캘린더)", 'path': "/adminPage" },
        { 'id': 2, 'name': "과제 목록", 'path': "/adminPage/objView" },
        { 'id': 3, 'name': "데이터 제출 현황", 'path': "/adminPage/submintStatus" },
        { 'id': 4, 'name': "데이터 제출 목록 일괄", 'path': "/adminPage/dataSubmitList" },
        { 'id': 5, 'name': "데이터 제출 상태 변경", 'path': "/adminPage/dataSubmitStatusChange" },
        { 'id': 6, 'name': "DMP 작성 현황 및 제출 상태 변경", 'path': "/adminPage/dmpSubmitStatus" },
        { 'id': 7, 'name': "데이터 관리 계획 접근권한(User별)", 'path': "/adminPage/userManagement" },
        { 'id': 8, 'name': "데이터 관리 계획 접근권한(과제별)", 'path': "/adminPage/dmpAccessCheck" },
        { 'id': 9, 'name': "생산계획 누락현황 확인", 'path': "/adminPage/productionPlanMng" },
        { 'id': 10, 'name': "신청서 관리 페이지(신청서 정보)", 'path': "/adminPage/applicationManagement" },
    ];


    const location = useLocation();
    const activeMenuId = adminMenu.find((menu) => menu.path === location.pathname)?.id || null;

    // 연도 리스트 생성
    useEffect(() => {
        const startYear = 2022;
        const yearList = [];
        for (let year = startYear; year <= currentYear; year++) {
            yearList.push(year);
        }
        setYears(yearList);
    }, [currentYear]);

    // 데이터 요청 함수
    const dataSubmitStatusList = async (searchTerm = "", year = "", page = 1) => {

        const start = (page - 1) * pageSize + 1; // 페이지 시작 위치 계산
        const endpoint = `/api/m/datasubmit?kw=${searchTerm}&year=${year}&ps=${start}&pl=${pageSize}`;
        try {
            const res = await api.get(endpoint, { withCredentials: true });
            setTableData(res.data.item || []);
            setListCount(res.data.pages.Total || 0); // 총 데이터 개수 설정
            setTotalCnt1(res.data.total || []); // 배열 기본값 설정
            setTotalCnt2(res.data.total || []); // 배열 기본값 설정
            setTotalPages(Math.ceil((res.data.pages.Total || 0) / pageSize)); // 전체 페이지 수 계산
        } catch (error) {
            console.error("요청에 실패했습니다.", error);
        }
    };

    // 작성률과 제출률 계산 테이블 리스트 수 / 전체 수
    const saveRate = listCount > 0 ? ((cntSave / listCount) * 100).toFixed(2) : 0;
    const submitRate = listCount > 0 ? ((cntSubmit / listCount) * 100).toFixed(2) : 0;

    // 데이터 상태 변경 시 작성/제출 건수 계산
    useEffect(() => {
        if (Array.isArray(totalCnt1) && Array.isArray(totalCnt2)) {
            // totalCnt1 배열 내 Cnt1 값을 모두 합산
            const totalSave = totalCnt1.reduce((acc, item) => acc + (item.Cnt1 || 0), 0);

            // totalCnt2 배열 내 Cnt2 값을 모두 합산
            const totalSubmit = totalCnt2.reduce((acc, item) => acc + (item.Cnt2 || 0), 0);

            // 합산된 값을 상태로 업데이트
            setCntSave(totalSave);
            setCntSubmit(totalSubmit);
        } else {
            setCntSave(0);
            setCntSubmit(0);
        }
    }, [totalCnt1, totalCnt2]); // 의존성 배열에 totalCnt1, totalCnt2 추가


    // 검색어와 년도 변경 시 데이터 호출
    useEffect(() => {
        dataSubmitStatusList(searchTerm, selectedYear, currentPage);
    }, [currentPage, selectedYear, searchTerm,]);

    // 검색어 변경 핸들러
    // const debouncedFetchData = useCallback(
    //     debounce((value) => {
    //         dataSubmitStatusList(value, selectedYear, 1);
    //     }, 300),
    //     [selectedYear] // selectedYear가 변경될 때 debounce 함수 갱신
    // );

    const handlerInputChange = (event) => {
        const value = event.target.value;

        if (value.length > 14) {
            alert("입력 가능한 최대 길이는 14자입니다.");
            setSearchTerm("");
            event.target.value = ""; // input 필드도 초기화
            return;
        }
        setSearchTerm(value); // 상태 업데이트
        setCurrentPage(1); // 페이지를 초기화
        dataSubmitStatusList(value, selectedYear,1); // 최신 값을 사용하여 즉시 데이터 가져오기

        // setSearchTerm(value);
        // setCurrentPage(1); // 페이지 초기화
        // setBtnPage(1);
        // debouncedFetchData(value);
    };

    // 년도 변경 핸들러
    const handleYearChange = (e) => {
        const year = e.target.value;
        setSelectedYear(year);
        setCurrentPage(1); // 페이지 초기화
        setBtnPage(1);
    };

    // 페이지네이션 버튼 클릭
    const handerbtn = (pageNumber, i) => {
        // setCurrentPage(pageNumber);
        // setBtnPage(i);

        setCurrentPage(pageNumber); // 상태 업데이트
        dataSubmitStatusList(searchTerm,selectedYear, pageNumber); // 최신 값을 사용하여 즉시 데이터 가져오기
    };

    // 페이지네이션 렌더링
    const renderPagination = () => {
        const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => handerbtn(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages.map((page) => (
                    <button
                        key={page}
                        className={`pagination-button ${page === currentPage ? "active" : ""}`}
                        onClick={() => handerbtn(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => handerbtn(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    return (
        <div className="adminpage-container">
            <div className="small-title">
                <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                <div className="adminhd-line"></div>
                <h2 className="menu-name">관리자 메뉴</h2>
            </div>

            <div className="menu-list">
                <div className="menu-title">
                    {adminMenu.map((item) => (
                        <React.Fragment key={item.id}>
                            <div className={`menu-item ${activeMenuId === item.id ? "active" : ""}`}>
                                <Link to={item.path}>
                                    {item.name}
                                </Link>
                            </div>
                            <div className="admin-under"></div>
                        </React.Fragment>
                    ))}
                </div>
                <div className="admin-line"></div>
                <div className="year-name">
                    <div className="submin-container">
                        <h3>데이터 제출 현황</h3>
                        <div className="submit-result">
                            <div>
                                총 대상 과제 : {listCount} / 작성된 과제 : {cntSave} / 제출된 과제 : {cntSubmit}
                            </div><br />
                            <div>작성률 : {saveRate}%</div><br />
                            <div>제출률 : {submitRate}%</div>
                        </div>
                        <h4>※ 작성률과 제출률은 검색 키워드와 수행년도에 따라 계산 됩니다.</h4>
                        <div className="accessCheck-year">
                            과제수행년도
                            <select value={selectedYear} className="submin-year" onChange={handleYearChange}>
                                <option value="">전체</option>
                                {years.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="admin-searchbox">
                            <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                            <input
                                type="text"
                                placeholder="검색어를 입력해주세요."
                                value={searchTerm}
                                onChange={handlerInputChange}
                            />
                            <h4>※ 과제번호, 과제명, 연구책임자명 키워드로 검색이 가능합니다.</h4>
                        </div>
                    </div>
                    <div className="title-line02"></div>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                        <tr className="dmplist-title">
                            <th>DmpNo</th>
                            <th>과제번호</th>
                            <th>과제수행년도</th>
                            <th>과제명</th>
                            <th>연구책임자명</th>
                            <th>데이터담당자명</th>
                            <th>제출데이터세트 수</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableData.map((row, index) => (
                            <tr key={index} className="dmplist-Data">
                                <td>{row.DmpNo}</td>
                                <td>{row.ProjNo}</td>
                                <td>{row.AnnlYy}</td>
                                <td>{row.ProjNm}</td>
                                <td>{row.RspnEmpNm}</td>
                                <td>{row.DataEmpNm}</td>
                                <td>
                                    {row.Totcnt} (저장 {row.Cnt1} / 제출 {row.Cnt2})
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {renderPagination()}
                </div>
            </div>
        </div>
    );
}

export default DataSubmitStatus;
