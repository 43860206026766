import React, {useCallback, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import '../submitStatus/dataSubmitStatus.css';
import api from "../../api";
import Cookies from 'js-cookie';
import {useSearchHomeStore} from "../../stores/dataSetsStore";
import {debounce} from "lodash";

function DataSubmitStatusChange() {

    const [tableData, setTableData] = useState([]);
    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 개수
    const [listCount, setListCount] = useState(0); // 총 데이터 개수
    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수

    // 체크박스 상태
    const [checkedItems, setCheckedItems] = useState({});
    const [isSelectAllChecked, setIsSelectAllChecked] = useState({});

    // 제출 상태
    const [submitStatuses, setSubmitStatuses] = useState({});

    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const [currentPage, setCurrentPage] = useState(Number(queryParams.get("page")) || 1);
    const [searchTerm, setSearchTerm] = useState(queryParams.get("search") || "");




    const dataSendPage = async (searchTerm = "", page = 1) => {

        const start = (page - 1) * pageSize + 1; // 페이지 시작 위치 계산
        let endPoint = `/api/m/datastatus?kw=${searchTerm}&ps=${start}&pl=${pageSize}`;
        try {
            const res = await api.get(endPoint, {
                withCredentials: true,
            });
            setTableData(res.data.item || []);
            setListCount(res.data.pages.Total || 0); // 총 데이터 개수 설정
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산
        } catch (error) {
            console.error("요청에 실패했습니다.", error);
        }
    };

    useEffect(() => {
        dataSendPage(searchTerm, currentPage);
    }, [currentPage, searchTerm]);

    useEffect(() => {
        const params = new URLSearchParams();
        if (searchTerm) params.set("search", searchTerm);
        if (currentPage) params.set("page", currentPage);
        navigate(`?${params.toString()}`, { replace: true }); // URL을 업데이트하되 브라우저 기록을 추가하지 않음
    }, [currentPage, searchTerm, navigate]);

    // useEffect(() => {
    //     if (searchData) {
    //         setSearchTerm(searchData); // 검색어 상태를 home에서 받아온 값으로 설정
    //         setCurrentPage(1); // 검색 시 페이지를 처음으로 리셋
    //         dataSendPage(searchData, 1); // 받아온 검색어로 바로 데이터를 조회
    //     }
    // }, [searchData]); // searchData가 변경될 때만 실행

    // 페이지 버튼 처리

    // const debouncedFetchData = useCallback(
    //     debounce((value) => dataSendPage(value, 1), 300), // 1초 동안 입력이 없으면 호출
    //     []
    // );

    const handlerInputChange = (event) => {
        const value = event.target.value;

        // 입력된 값의 길이가 14자를 넘으면 alert를 띄우고 값을 초기화
        if (value.length > 14) {
            alert("입력 가능한 최대 길이는 14자입니다.");
            setSearchTerm(""); // 값을 초기화
            return;
        }

        // setSearchTerm(value);
        // setCurrentPage(1); // 검색 시 페이지를 처음으로 리셋
        // setBtnPage(1);
        // debouncedFetchData(value);

        setSearchTerm(value); // 상태 업데이트
        setCurrentPage(1); // 페이지를 초기화
        dataSendPage(value, 1); // 최신 값을 사용하여 즉시 데이터 가져오기

    };

    const handerbtn = (pageNumber, i) => {
        setCurrentPage(pageNumber); // 상태 업데이트
        dataSendPage(searchTerm, pageNumber); // 최신 값을 사용하여 즉시 데이터 가져오기
    };

    const renderPagination = () => {
        const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => handerbtn(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages.map((page) => (
                    <button
                        key={page}
                        className={`pagination-button ${page === currentPage ? "active" : ""}`}
                        onClick={() => handerbtn(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => handerbtn(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };


    const adminMenu = [
        { 'id': 1, 'name': "공개시기 관리(캘린더)", 'path': "/adminPage" },
        { 'id': 2, 'name': "과제 목록", 'path': "/adminPage/objView" },
        { 'id': 3, 'name': "데이터 제출 현황", 'path': "/adminPage/submintStatus" },
        { 'id': 4, 'name': "데이터 제출 목록 일괄", 'path': "/adminPage/dataSubmitList" },
        { 'id': 5, 'name': "데이터 제출 상태 변경", 'path': "/adminPage/dataSubmitStatusChange" },
        { 'id': 6, 'name': "DMP 작성 현황 및 제출 상태 변경", 'path': "/adminPage/dmpSubmitStatus" },
        { 'id': 7, 'name': "데이터 관리 계획 접근권한(User별)", 'path': "/adminPage/userManagement" },
        { 'id': 8, 'name': "데이터 관리 계획 접근권한(과제별)", 'path': "/adminPage/dmpAccessCheck" },
        { 'id': 9, 'name': "생산계획 누락현황 확인", 'path': "/adminPage/productionPlanMng" },
        { 'id': 10, 'name': "신청서 관리 페이지(신청서 정보)", 'path': "/adminPage/applicationManagement" },
    ];
    // 전체 선택 핸들러
    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;

        setIsSelectAllChecked((prevState) => ({
            ...prevState,
            [currentPage]: isChecked, // 현재 페이지 전체 선택 상태 저장
        }));

        setCheckedItems((prevState) => ({
            ...prevState,
            [currentPage]: tableData.reduce((acc, row) => {
                acc[row.DtaSbmSeqNo] = isChecked;
                return acc;
            }, {}),
        }));
    };


    // 개별 체크박스 핸들러
    const handleCheckboxChange = (event, id) => {
        const isChecked = event.target.checked;

        setCheckedItems((prevState) => ({
            ...prevState,
            [currentPage]: {
                ...(prevState[currentPage] || {}),
                [id]: isChecked, // 현재 페이지의 특정 체크박스 상태 업데이트
            },
        }));

        // 개별 체크 변경 후 전체 선택 상태 동기화
        const currentCheckedItems = {
            ...(checkedItems[currentPage] || {}),
            [id]: isChecked,
        };

        const isAllChecked = tableData.every((row) => currentCheckedItems[row.DtaSbmSeqNo]);
        setIsSelectAllChecked((prevState) => ({
            ...prevState,
            [currentPage]: isAllChecked,
        }));
    };


    // 제출 상태 변경 핸들러
    const handleStatusChange = (e, id) => {
        const newStatus = e.target.value;
        setSubmitStatuses(prevStatuses => ({
            ...prevStatuses,
            [id]: newStatus, // 해당 행의 상태만 변경
        }));
    };

    // 일괄 상태 변경 핸들러
    // 일괄 상태 변경 핸들러
    const handleBulkStatusChange = async () => {
        // 모든 페이지의 체크된 항목 병합
        const allSelectedIds = Object.values(checkedItems)
            .flatMap((pageItems) => Object.keys(pageItems).filter((id) => pageItems[id]));

        if (allSelectedIds.length === 0) {
            alert("체크박스를 선택해주세요.");
            return;
        }

        // 상태가 변경된 항목만 필터링
        const changedStatuses = allSelectedIds.filter((id) => {
            const originalStatus = tableData.find((row) => row.DtaSbmSeqNo.toString() === id)?.SubmitStatus;
            return submitStatuses[id] && submitStatuses[id] !== originalStatus; // 상태가 변경된 항목만 반환
        });

        if (changedStatuses.length === 0) {
            alert("변경된 상태가 없습니다.");
            return;
        }

        try {
            await Promise.all(
                changedStatuses.map(async (id) => {
                    const newStatus = submitStatuses[id];
                    const csrfToken = Cookies.get("XSRF-TOKEN");

                    await api.patch(`/api/m/datasubmit/${id}/${newStatus}`, null, {
                        withCredentials: true,
                        headers: {
                            "X-XSRF-TOKEN": csrfToken,
                        },
                    });
                })
            );

            alert("상태가 성공적으로 업데이트되었습니다.");

            // 데이터 재로드
            window.location.reload();
            await dataSendPage(searchTerm, currentPage);

            // 체크박스 초기화
            setCheckedItems({});
            setIsSelectAllChecked({});
        } catch (err) {
            console.error("일괄 상태 변경에 실패했습니다.", err);
        }
    };

    const handleRowClick = (DtaSbmSeqNo) => {
        navigate('/dmpRealData/dmpRealDataView', {
            state: {  DtaSbmSeqNo }, // id를 state로 전달
        });
    };

    const activeMenuId = adminMenu.find((menu) => menu.path === location.pathname)?.id || null;


    return (
        <>
            <div className="adminpage-container">
                <div className="small-title">
                    <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                    <div className="adminhd-line"></div>
                    <h2 className="menu-name">관리자 메뉴</h2>
                </div>
                <div className="menu-list">
                    <div className="menu-title">
                        {adminMenu.map((item) => (
                            <React.Fragment key={item.id}>
                                <div className={`menu-item ${activeMenuId === item.id ? "active" : ""}`}>
                                    <Link to={item.path}>
                                        {item.name}
                                    </Link>
                                </div>
                                <div className="admin-under"></div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="admin-line"></div>
                    <div className="year-name">
                        <div className="submin-container">
                            <h3>데이터 제출 상태 변경</h3>
                        </div>
                        <div className="admin-searchbox">
                            <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                            <input type="text" placeholder="검색어를 입력해주세요." value={searchTerm}
                                   onChange={handlerInputChange} />
                            <h4>※  과제번호, 과제명, 연책 키워드로 검색이 가능합니다.</h4>
                        </div>
                        <h4>※ 당해연도 제출건만 조회됩니다.</h4>

                        <div className="status-btn04">
                            <button className="dataStatus-allChange" onClick={handleBulkStatusChange}>
                                선택된 항목 상태 일괄 변경
                            </button>
                        </div>
                        <div className="title-line02"></div>
                        <div>총 {listCount}건</div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="dmplist-title">
                                <th>
                                    <input
                                        type="checkbox"
                                        className="row-checkboxSize"
                                        checked={isSelectAllChecked[currentPage] || false} // 현재 페이지의 전체 선택 상태
                                        onChange={handleSelectAllChange}
                                    />
                                </th>
                                <th>DtaSbmSeqNo</th>
                                <th>과제번호</th>
                                <th>과제수행년도</th>
                                <th>과제명</th>
                                <th>데이터세트</th>
                                <th>연책</th>
                                <th>제출자</th>
                                <th>제출상태</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableData.map((row) => (
                                <tr key={row.DtaSbmSeqNo} className="dmplist-Data">
                                    <td>
                                        <input
                                            type="checkbox"
                                            className="row-checkboxSize"
                                            checked={(checkedItems[currentPage]?.[row.DtaSbmSeqNo]) || false} // 현재 페이지의 개별 체크 상태
                                            onChange={(e) => handleCheckboxChange(e, row.DtaSbmSeqNo)}
                                        />
                                    </td>
                                    <td>{row.DtaSbmSeqNo}</td>
                                    <td>{row.ProjNo}</td>
                                    <td>{row.AnnlYy}</td>
                                    <td>{row.ProjNm}</td>
                                    <td style={{ cursor: 'pointer' }} onClick={() => handleRowClick(row.DtaSbmSeqNo)}>
                                        [{row.DtaSetNm}]
                                        {row.DtaNm} <br />
                                        {row.DtaDs}
                                    </td>
                                    <td>{row.RspnEmpNm}</td>
                                    <td>{row.UserNm}</td>
                                    <td>
                                        <select
                                            style={{height: '37px', fontSize: '13px'}}
                                            value={submitStatuses[row.DtaSbmSeqNo] || row.SubmitStatus} // 백엔드에서 받은 상태값 사용
                                            onChange={(e) => handleStatusChange(e, row.DtaSbmSeqNo)}
                                            disabled={row.DmpNo === 0} // DmpNo가 0일 경우 선택 불가
                                        >
                                            {row.DtaSbmSeqNo === 0 ? (
                                                <>
                                                    <option value="미작성">미작성</option>
                                                </>
                                            ) : (
                                                <>
                                                    <option value="0">임시저장</option>
                                                    <option value="1">저장</option>
                                                    <option value="2">제출</option>
                                                    <option value="3">검토완료</option>
                                                    <option value="4">검토완료(의견있음)</option>
                                                </>
                                            )}
                                        </select>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {renderPagination()}
                    </div>
                </div>
            </div>
        </>
    );
}

export default DataSubmitStatusChange;
