import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import './header.css';
import useLoginAuthStore from "../stores/loginAuth";
import api from '../api';
import Cookies from 'js-cookie';

function Header() {
    const { userId, isAdmin, clearAuth } = useLoginAuthStore((state) => ({
        userId: state.userId,
        isAdmin: state.isAdmin,
        clearAuth: state.clearAuth,
    }));

    const [authCheck, setAuthCheck] = useState(null); // "Y" 또는 "N"
    const navigate = useNavigate();

    // 로그아웃 처리
    const logouthandler = () => {
        const confirmLogout = window.confirm("정말 로그아웃 하시겠습니까?");
        if (confirmLogout) {
            const csrfToken = Cookies.get('XSRF-TOKEN');
            api.post(`/api/auth/logout`, {}, {
                withCredentials: true,
                headers: {
                    'X-XSRF-TOKEN': csrfToken, // CSRF 토큰 포함
                }
            }).then(() => {
                alert("로그아웃이 되었습니다.");
                clearAuth(); // 상태 초기화
                sessionStorage.removeItem('userId');  // sessionStorage에서 사용자 정보 삭제
                sessionStorage.removeItem('isAdmin'); // sessionStorage에서 관리자 정보 삭제
                localStorage.removeItem('userId');  // localStorage에서 사용자 정보 삭제
                localStorage.removeItem('isAdmin'); // localStorage에서 관리자 정보 삭제
                navigate("/login"); // 로그인 페이지로 이동
            }).catch((err) => {
                console.error("로그아웃 실패했습니다.");
            });
        }
    };

    // 세션 상태 확인 및 만료 처리
    // 세션 상태 확인 및 만료 처리
    useEffect(() => {
        let isMounted = true; // 컴포넌트가 마운트 상태일 때만 실행되도록 제어

        const checkSession = async () => {
            try {
                const res = await api.get("/api/auth/ckeckadmin", {
                    withCredentials: true,
                });

                if (isMounted) {
                    if (res.status === 200 && res.data === "Y") {
                        setAuthCheck("Y"); // 관리자 인증됨
                    } else {
                        setAuthCheck("N"); // 일반 사용자
                    }
                }
            } catch (error) {
                if (isMounted && error.response) {
                    if (error.response.status === 401) {
                        alert("세션이 만료되었습니다. 다시 로그인해주세요.");
                        clearAuth(); // 상태 초기화
                        navigate("/login"); // 로그인 페이지로 리다이렉트
                    }
                }
            }
        };

        if (userId) {
            checkSession();
        }

        return () => {
            isMounted = false; // 컴포넌트 언마운트 시 실행 중단
        };
    }, [userId, navigate, clearAuth]);
    const loginHandler = () => {
        navigate(`/login`);
    };

    return (
        <header>
            <div className="container">
                <div className="logo">
                    <Link to="/">
                        <img src={`${process.env.PUBLIC_URL}/CI_KFRI.png`} alt="KFRI Logo" />
                    </Link>
                </div>
                <nav className="navbar">
                    <div className="dropdown">
                        <Link>소개</Link>
                        <div className="dropdown-menu">
                            <Link to="/intro">소개</Link>
                            <Link to="/board">공지</Link>
                            <Link to="/dataRoom">자료실</Link>
                        </div>
                    </div>
                    <Link to="/dmpManagement/dmpList">데이터 관리 계획</Link>
                    <Link to="/dmpRealData/dmpRealDataList">데이터</Link>
                    <Link to="/openData">공개데이터</Link>
                    <Link to="/Curation/list">데이터큐레이션</Link>
                </nav>
                <div className="login">
                    {
                        userId ? (
                            <div className="login-success" style={{ cursor: "pointer" }}>
                                {
                                    isAdmin ? (
                                        <>
                                            <Link to="/adminPage">
                                                <img className="header-icon01" src={`${process.env.PUBLIC_URL}/final-adminLogo.png`} alt="admin" />
                                            </Link> |
                                            <Link to="/myPage">
                                                <img className="header-icon02" src={`${process.env.PUBLIC_URL}/final-mypageLogo.png`} alt="user" />
                                            </Link>
                                        </>
                                    ) : (
                                        <Link to="/myPage">
                                            <img className="header-icon02" src={`${process.env.PUBLIC_URL}/user-icon.png`} alt="user" />
                                        </Link>
                                    )
                                }
                                <button style={{ cursor: "pointer" }} onClick={logouthandler}>로그아웃</button>
                            </div>
                        ) : (
                            <button style={{ cursor: "pointer" }} onClick={loginHandler}>
                                로그인
                            </button>
                        )
                    }
                </div>
            </div>
        </header>
    );
}

export default Header;
