import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import Cookies from 'js-cookie';
import 'moment/locale/ko';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './adminDataPage.css';
import {Link, useLocation} from "react-router-dom";
import api from '../api';

function AdminDataPage() {
    const [myPageData, setMyPageData] = useState([]);
    const [events, setEvents] = useState([]);
    const [openDataList, setOpenDataList] = useState([]);
    const [btnPage, setBtnPage] = useState(1);
    const [selectedDate, setSelectedDate] = useState(new Date()); // 선택된 날짜 상태 추가
    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 개수 (1-10, 11-20 등)
    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
    // const [currentPage, setCurrentPage] = useState(1); // 현재 페이지

    const [modalEvents, setModalEvents] = useState([]); // 모달에 표시할 이벤트
    const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태

    moment.locale('ko');
    const localizer = momentLocalizer(moment);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await api.get(`/api/m/calendar`, { withCredentials: true });
                setMyPageData(res.data);

                const calendarEvents = res.data.map((item, index) => ([
                    {
                        id: `${item.DmpNo}-internal-${index}`,
                        title: `내부공개 당일 : ${item.DtaNm}`,
                        start: new Date(item.OpnTm),
                        end: new Date(item.OpnTm),
                        title01: "내부공개 당일",
                        ProjNo: item.ProjNo,
                        ProjNm: item.ProjNm,
                        DtaNm: item.DtaNm,
                        RspnEmpNm: item.RspnEmpNm,
                        RspnEmpNo: item.RspnEmpNo,
                        type: 'internal', // 내부공개일자
                        details: `내용: ${item.DtaNm}<br />작성 날짜: ${item.OpnTm}`,
                    },
                    {
                        id: `${item.DmpNo}-external-${index}`,
                        title: `외부공개 당일 : ${item.DtaNm}`,
                        start: new Date(item.OpnPd),
                        end: new Date(item.OpnPd),
                        title01: "외부공개 당일",
                        ProjNo: item.ProjNo,
                        ProjNm: item.ProjNm,
                        DtaNm: item.DtaNm,
                        RspnEmpNm: item.RspnEmpNm,
                        RspnEmpNo: item.RspnEmpNo,
                        type: 'external', // 외부공개일자
                        details: `내용: ${item.DtaNm}<br />작성 날짜: ${item.OpnPd}`,
                    },

                    {
                        id: `${item.DmpNo}-start-${index}`,
                        title: `내부공개 3개월 전 : ${item.DtaNm}`,
                        start: new Date(item.OpnTmPrev3Months),
                        end: new Date(item.OpnTmPrev3Months),
                        title01: (
                            <>
                                내부공개 3개월 전
                                <br />
                                (95일)
                            </>
                        ),
                        ProjNo: item.ProjNo,
                        ProjNm: item.ProjNm,
                        DtaNm: item.DtaNm,
                        RspnEmpNm: item.RspnEmpNm,
                        RspnEmpNo: item.RspnEmpNo,
                        type: 'start',
                        details: `내용: ${item.DtaNm}<br />3달 전 날짜: ${item.OpnTmPrev3Months}`,
                    },

                    {
                        id: `${item.DmpNo}-end-${index}`,
                        title: `외부공개 3개월 전 : ${item.DtaNm}`,
                        start: new Date(item.OpnPdPrev3Months),
                        end: new Date(item.OpnPdPrev3Months),
                        title01: (
                            <>
                                외부공개 3개월 전
                                <br />
                                (95일)
                            </>
                        ),
                        ProjNo: item.ProjNo,
                        ProjNm: item.ProjNm,
                        DtaNm: item.DtaNm,
                        RspnEmpNm: item.RspnEmpNm,
                        RspnEmpNo: item.RspnEmpNo,
                        type: 'end',
                        details: `내용: ${item.DtaNm}<br />3달 전 날짜: ${item.OpnPdPrev3Months}`,
                    }
                ])).flat();

                setEvents(calendarEvents);
            } catch (err) {
                console.error("요청에 실패했습니다.");
            }
        };

        fetchData();
    }, []);


    const handleShowMore = (events, date) => {
        // 모달을 띄우기 전에 `+more` 클릭 시 캘린더 뷰 변경을 막는 처리가 필요 없음
        setSelectedDate(date); // 선택된 날짜 저장
        setModalEvents(events); // 해당 날짜의 이벤트 저장
        setIsModalOpen(true); // 모달 열기
    };

    const closeModal = () => {
        setIsModalOpen(false); // 모달 닫기
    };

    const handleDateClick = async (date, page = 1) => {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        try {
            // API 호출: 선택한 날짜의 데이터를 가져옴
            const res = await api.get(`/api/m/calendardatalist?Date=${formattedDate}&ps=${page}&pl=${pageSize}`, { withCredentials: true });

            setOpenDataList(res.data.item || []); // 가져온 데이터 설정

            setTotalPages(Math.ceil(res.data.total / pageSize)); // 전체 페이지 계산

            const filteredEvents = events.filter(
                (event) => moment(event.start).isSame(date, 'day')
            );

            if (filteredEvents.length > 0) {
                setModalEvents(filteredEvents); // Set events for the modal
                setSelectedDate(date); // Set the selected date
                setIsModalOpen(true); // Open the modal
            }
            else {
                alert("해당 날짜에 데이터가 없습니다."); // 데이터가 없는 경우 알림
            }



        } catch (err) {
            console.error("요청에 실패했습니다.");
            alert("데이터를 불러오는 중 오류가 발생했습니다.");
        }
    };

    const handlePageClick = (pageNumber, i) => {
        // setCurrentPage(pageNumber);
        setBtnPage(i);
        handleDateClick(selectedDate, pageNumber); // 페이지 변경 시 선택한 날짜로 데이터 다시 불러오기
    };

    const renderPagination = () => {
        const startPage = Math.floor((btnPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            const pageNumber = i; // 각 페이지 번호
            pages.push(
                <button
                    key={i}
                    className={`pagination-button ${i === btnPage ? 'active' : ''}`}
                    onClick={() => handlePageClick(pageNumber, i)}
                >
                    {i}
                </button>
            );
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => setBtnPage(startPage - pageGroupSize)}>
                        이전
                    </button>
                )}
                {pages}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => setBtnPage(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    const adminMenu = [
        { 'id': 1, 'name': "공개시기 관리(캘린더)", 'path': "/adminPage" },
        { 'id': 2, 'name': "과제 목록", 'path': "/adminPage/objView" },
        { 'id': 3, 'name': "데이터 제출 현황", 'path': "/adminPage/submintStatus" },
        { 'id': 4, 'name': "데이터 제출 목록 일괄", 'path': "/adminPage/dataSubmitList" },
        { 'id': 5, 'name': "데이터 제출 상태 변경", 'path': "/adminPage/dataSubmitStatusChange" },
        { 'id': 6, 'name': "DMP 작성 현황 및 제출 상태 변경", 'path': "/adminPage/dmpSubmitStatus" },
        { 'id': 7, 'name': "데이터 관리 계획 접근권한(User별)", 'path': "/adminPage/userManagement" },
        { 'id': 8, 'name': "데이터 관리 계획 접근권한(과제별)", 'path': "/adminPage/dmpAccessCheck" },
        { 'id': 9, 'name': "생산계획 누락현황 확인", 'path': "/adminPage/productionPlanMng" },
        { 'id': 10, 'name': "신청서 관리 페이지(신청서 정보)", 'path': "/adminPage/applicationManagement" },
    ];

    // 상태 변경 함수
    const handleStatusChange = async (e, seq) => {
        const newStatus = e.target.value;

        // 로컬 상태 업데이트
        setOpenDataList(prevDataList =>
            prevDataList.map(item =>
                item.DtaSbmSeqNo === seq ? { ...item, OpenStatus: newStatus } : item
            )
        );

        try {

            const csrfToken = Cookies.get('XSRF-TOKEN');

            await api.patch(`/api/m/openstatus/${seq}/${newStatus}`, {}, { withCredentials: true,
                headers: {
                    'X-XSRF-TOKEN': csrfToken,
                }
            });
            alert("공개데이터 상태가 변경되었습니다.");
        } catch (err) {
            console.error("요청에 실패했습니다.");
            alert("상태 변경에 실패했습니다.");
        }
    };

    const location = useLocation();
    const activeMenuId = adminMenu.find((menu) => menu.path === location.pathname)?.id || null;


    return (
        <>
            <div className="adminpage-container">
                <div className="small-title">
                    <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                    <div className="adminhd-line"></div>
                    <h2 className="menu-name">관리자 메뉴</h2>
                </div>
                <div className="menu-list">
                    <div className="menu-title">
                        {adminMenu.map((item) => (
                            <React.Fragment key={item.id}>
                                <div className={`menu-item ${activeMenuId === item.id ? "active" : ""}`}>
                                    <Link to={item.path}>
                                        {item.name}
                                    </Link>
                                </div>
                                <div className="admin-under"></div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="admin-line"></div>
                    <div className="check-calendar">
                        <h3>캘린더(공개시기)</h3>

                        {/* 범례 추가 */}
                        <div className="calendar-legend" style={{ display: 'flex', gap: '20px', marginBottom: '15px', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: '#1E90FF',
                                        marginRight: '8px',
                                        borderRadius: '3px',
                                    }}
                                ></div>
                                <span>내부공개 당일</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: '#32CD32',
                                        marginRight: '8px',
                                        borderRadius: '3px',
                                    }}
                                ></div>
                                <span>외부공개 당일</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: '#FFA500',
                                        marginRight: '8px',
                                        borderRadius: '3px',
                                    }}
                                ></div>
                                <span>내부공개 3개월 전(95일)</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: '#FF69B4',
                                        marginRight: '8px',
                                        borderRadius: '3px',
                                    }}
                                ></div>
                                <span>외부공개 3개월 전(95일)</span>
                            </div>
                        </div>

                        <Calendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: '100vh' }}
                            selectable
                            // 날짜를 클릭했을 때 처리
                            onSelectSlot={(slotInfo) => {
                                setSelectedDate(slotInfo.start); // 선택된 날짜 설정
                                handleDateClick(slotInfo.start); // 클릭한 날짜에 해당하는 데이터 요청
                            }}
                            onNavigate={(newDate, view) => {
                                // 특정 뷰로 이동하지 않도록 설정 (예: day 뷰 제외)
                                if (view === 'day') {
                                    return;
                                }
                            }}
                            eventPropGetter={(event) => {
                                let backgroundColor;
                                switch (event.type) {
                                    case 'internal':
                                        backgroundColor = '#1E90FF';
                                        break;
                                    case 'external':
                                        backgroundColor = '#32CD32';
                                        break;
                                    case 'start':
                                        backgroundColor = '#FFA500';
                                        break;
                                    case 'end':
                                        backgroundColor = '#FF69B4';
                                        break;
                                    default:
                                        backgroundColor = 'gray';
                                }

                                return {
                                    style: {
                                        backgroundColor,
                                        color: 'white',
                                        borderRadius: '5px',
                                        padding: '2px 5px',
                                    },
                                    'data-tooltip-id': `tooltip-${event.id}`,
                                    'data-tooltip-html': event.details,
                                };
                            }}
                            components={{
                                event: (props) => (
                                    <span
                                        data-tooltip-id={`tooltip-${props.event.id}`}
                                        data-tooltip-html={props.event.details}
                                    >
                {props.title}
            </span>
                                ),
                            }}
                            views={['month', 'week']} // 'day' 뷰 제외
                        />

                        {isModalOpen && (
                            <div className="calendar-modal">
                                <div className="modal-content" style={{ width: '100vh' }}>
                                    <button className="close-btn" onClick={closeModal}>X</button>
                                    <h1>{moment(selectedDate).format("YYYY-MM-DD")} 요약보기</h1>
                                    <h2 style={{ textAlign: "left" }}>DMP 공개계획</h2>
                                    <table className="modal-table">
                                        <thead>
                                        <tr>
                                            <th>구분</th>
                                            <th>과제번호</th>
                                            <th>과제명</th>
                                            <th>데이터셋</th>
                                            <th>연구책임자</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {modalEvents.filter(event => event.type === 'internal' || event.type === 'external').length > 0 ? (
                                            modalEvents
                                                .filter(event => event.type === 'internal' || event.type === 'external')
                                                .map((event, index) => (
                                                    <tr key={`${event.id}-status-${index}`}>
                                                        <td style={{width : '120px'}}>{event.title01}</td>
                                                        <td style={{width : '130px'}}>{event.ProjNo || '-'}</td>
                                                        <td>{event.ProjNm || '-'}</td>
                                                        <td>{event.DtaNm}</td>
                                                        <td style={{width : '100px'}}>[{event.RspnEmpNo || '-'}]<br/>{event.RspnEmpNm || '-'}</td>
                                                    </tr>
                                                ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6" style={{ textAlign: "center" }}>데이터가 없습니다</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>

                                    <div className="mypage-side_line-title"></div>


                                    <h2 style={{ textAlign: "left" }}>3개월 전 공개계획</h2>
                                    <table className="modal-table">
                                        <thead>
                                        <tr>
                                            <th>구분</th>
                                            <th>과제번호</th>
                                            <th>과제명</th>
                                            <th>데이터셋</th>
                                            <th>연구책임자</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {modalEvents.filter(event => event.type === 'start' || event.type === 'end').length > 0 ? (
                                            modalEvents
                                                .filter(event => event.type === 'start' || event.type === 'end')
                                                .map((event, index) => (
                                                    <tr key={`${event.id}-${index}`}>
                                                        <td style={{width : '150px'}}>{event.title01}</td>
                                                        <td style={{width : '130px'}}>{event.ProjNo || '-'}</td>
                                                        <td>{event.ProjNm || '-'}</td>
                                                        <td>{event.DtaNm}</td>
                                                        <td style={{width : '100px'}}>[{event.RspnEmpNo || '-'}]<br/>{event.RspnEmpNm || '-'}</td>
                                                    </tr>
                                                ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" style={{ textAlign: "center" }}>데이터가 없습니다</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                        {events.map((event) => (
                            <Tooltip
                                key={event.id}
                                id={`tooltip-${event.id}`}
                                className="custom-tooltip"
                                place="top"
                                type="dark"
                                effect="float"
                                html={true}
                            />
                        ))}
                        <div className="cal-middle-line"></div>
                        <div>
                            <h3>공개시기 상세</h3>
                            {openDataList.length > 0 ? (
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                <tr className="dmplist-title">
                                    <th>과제명</th>
                                    <th>데이터명</th>
                                    <th>연책</th>
                                    <th>제출상태</th>
                                    <th>내부공개시기</th>
                                    <th>외부공개시기</th>
                                    <th>공개상태</th>
                                </tr>
                                </thead>
                                <tbody>
                                {openDataList?.map((row, index) => (
                                    <tr key={index} className="dmplist-Data">
                                        <td>[{row.ProjNo}]<br/>{row.ProjNm}</td>
                                        <td>{row.DtaNm}</td>
                                        <td>{row.RspnEmpNm}</td>
                                        <td>
                                            {
                                                (() => {
                                                    if (row.SubmitStatus === "0") {
                                                        return "임시저장";
                                                    }
                                                    else if (row.SubmitStatus === "1") {
                                                        return "저장";
                                                    } else if (row.SubmitStatus === "2") {
                                                        return "제출";
                                                    } else if (row.SubmitStatus === "3") {
                                                        return "검토완료";
                                                    } else if (row.SubmitStatus === "4") {
                                                        return "검토완료(의견있음)";
                                                    } else {
                                                        return ""; // 기본값
                                                    }
                                                })()
                                            }
                                        </td>
                                        <td>
                                            {row.OpnTm}
                                        </td>
                                        <td>
                                            {row.OpnPd}
                                        </td>
                                        <td>
                                            <select
                                                style={{height: '37px', fontSize: '13px'}}
                                                value={row.OpenStatus} // openDataList의 값을 직접 사용
                                                onChange={(e) => handleStatusChange(e, row.DtaSbmSeqNo)}
                                            >
                                                <option value="0">비공개</option>
                                                <option value="1">공개</option>
                                            </select>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                                ) : (
                                <p className="noDataCss">데이터가 없습니다.</p>
                                )}
                            {renderPagination()}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminDataPage;
