import React from "react";
import { Navigate } from "react-router-dom";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // 에러 발생 시 상태 업데이트
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // 에러 로깅
        console.error("요청에 실패했습니다.", error, errorInfo);
    }

    componentDidMount() {
        // 전역 에러 핸들링 설정
        window.onerror = (message, source, lineno, colno, error) => {
            this.setState({ hasError: true });
        };

        window.addEventListener("unhandledrejection", (event) => {
            this.setState({ hasError: true });
        });
    }

    componentWillUnmount() {
        // 전역 핸들러 제거
        window.removeEventListener("unhandledrejection", () => {});
    }

    render() {
        if (this.state.hasError) {
            // 에러 발생 시 ToastErrorPage로 리다이렉트
            // return <Navigate to="/toastError" replace />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
