import React, {useState, useEffect} from "react";
import '../dmpRealDataCreate/dmpRealDataCreate.css';
import {Link, useLocation, useNavigate} from "react-router-dom";
import ToastGrid from "../../toast/toastGrid";
import RegisterDmp from "../../registerDmp/registerDmp";
import {
    useRegisterDataStore,
    useToastSetStore,
    useToastSetResultStore, useToastSetMethodStore
} from "../../stores/lastDmpData";
import ImportDataSet from "../../importDataSet/importDataSet";
import {
    useDataSummaryToastFilterStore,
} from "../../stores/dataSetsStore";
import { useParams } from 'react-router-dom';
import api from "../../api";

function DmpHisDataView() {

    const navigate = useNavigate();


    const location = useLocation();
    const { DtaSetHisNo, DmpNo } = location.state || {}; // 전달된 id 가져오기
    //use파람 값 확인
    // 상태값
    const [files, setFiles] = useState([]);
    const [showCallImgData, setShowCallImgData] = useState([]);
    const [dataPageSummaryData, setDataPageSummaryData] = useState([]);

    //등록 클릭 시 crate를 해줘야함 ~
    const [callImgData, setCallImgData] = useState({});

    //view에서의 데이터 urlParams 전달
    const [viewParams, setViewParams] = useState("");


    useEffect(() => {
        // URL 경로에서 필요한 부분만 추출
        const pathSegments = location.pathname.split('/');
        const desiredPart = pathSegments[2]; // 세 번째 부분 추출 (예: dmpRealDataView)

        setViewParams(desiredPart); // 콘솔에 'dmpRealDataView' 출력
    }, [location]);



    useEffect(() => {
        api.get(`/api/datahis/detail?DmpNo=${DmpNo}&DtaSetHisNo=${DtaSetHisNo}` ,{
            withCredentials: true
        })
            .then((res) => setCallImgData(res.data))
            .catch((err) => console.error("요청에 실패했습니다.", err));
    }, []);

    const [showRegisterDmpCheck, setShowRegisterDmpCheck] = useState(false);
    const [showImportData, setShowImportData] = useState(false);


    // 여기서 부터 신규 데이터 등록 변수 정리=====================================================
    // 등록 DMP 불러오기
    const registerdataSets = useRegisterDataStore(state =>state.registerdataSets);


    //DMP 데이터 세트(Summary)를 말함 불러오기
    const callSummaryFilterDataSets = useDataSummaryToastFilterStore(state => state.callSummaryFilterDataSets);

    useEffect(() => {
        setDataPageSummaryData(callSummaryFilterDataSets);
    },[callSummaryFilterDataSets])


    //데이터 세트 변경 변수 선언
    const DataPageMatarialDataSets = useToastSetStore(state => state.toastdataSets);
    const DataPageMethodDataSets = useToastSetMethodStore(state => state.toastMethoddataSets);
    const DataPageResultDataSets = useToastSetResultStore(state => state.toastResultdataSets);

    //파일을 불러온 후 가공 === 불러오기단계임
    useEffect(() => {

        // 각 파일에 대해 DtaFilePath 추출
        const updatedFileList = callImgData.file?.map(item => {
            const decodedPath = decodeURIComponent(item.DtaSaveFileNm);
            const startIndex = decodedPath.indexOf("C:"); // 파일 경로의 시작 인덱스
            const DtaFilePath = startIndex !== -1 ? decodedPath.substring(startIndex) : null;

            return {
                ...item,
                DtaFilePath: DtaFilePath
            };
        });


        // 상태 업데이트
        setShowCallImgData(updatedFileList);
    }, [callImgData]); // callImgData가 변경될 때만 실행

    const toggleObjCheck = () => {
        setShowRegisterDmpCheck(showRegisterDmpCheck);
        alert("데이터 이력보기 페이지에서는 불러오기가 불가능합니다.");
    }

    const toggleImportDataSets = () => {
        setShowImportData(showImportData);
        alert("데이터 이력보기 페이지에서는 데이터세트 불러오기가 불가능합니다.");
    }



    useEffect(() => {
    }, [showCallImgData]);



    //삭제 이후 데이터 제출 렌더링
    useEffect(() => {
    }, [showCallImgData]);


    const getSelectedDataSets = (DtaSetNm) => {

        if (DtaSetNm === '시료데이터') {
            return DataPageMatarialDataSets;
        } else if (DtaSetNm === '방법데이터') {
            return DataPageMethodDataSets;
        } else if (DtaSetNm === '결과데이터') {
            return DataPageResultDataSets;
        }
        return [];
    };

    const modifySummaryDataSets = () => {
        if (callImgData && callImgData.dmpSummary) {
            return getSelectedDataSets(callImgData.dmpSummary.DtaSetNm);
        }
        return []; // Return an empty array if no valid data is found
    };



    const hisListBtn = () => {
        navigate(-1);  // 이전 페이지로 이동
    }





    return(
        <>
            <div className="dmpRealData-banner-container">
                <div className="dmpRealData-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/sub-introimg03.jpg`} className="dmpRealData-img02" alt="search Logo" />
                    <div className="RealDatabanner-text">
                        <span className="intro-subName">데이터 이력</span>
                    </div>
                    <div className="dmpRealData-description">
                        <div className="dmpRealData-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터> 데이터 이력</span>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="RealData-management-title">
                        <h1>데이터 이력</h1>
                        <div>
                            <span className="required_color">*</span>
                            <span>필수 항목</span>
                        </div>
                    </div>
                    <div className="under_line"></div>
                    <div className="RealData-object-info">
                        <div className="RealData-object-info-title">
                            <span className="RealData-info">DMP 정보</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="RealData-task-title">
                            <div className="RealData-task-wrapper">
                                <button className="Real-check-obj" onClick={toggleObjCheck} disabled={false}>등록 DMP 불러오기</button>
                                {showRegisterDmpCheck && <RegisterDmp/>}

                                {
                                    DtaSetHisNo && (
                                        <>
                                            <input type="text" className="edit--box" readOnly placeholder="과제번호" value={callImgData.summary?.ProjNo}/>
                                            <input type="text" className="edit--box" readOnly placeholder="과제명" value={callImgData.summary?.ProjNm}/>
                                            <input type="text" className="edit--box" readOnly placeholder="연구책임자" value={callImgData.summary?.RspnEmpNm}/>
                                        </>
                                    )
                                }
                                {/*<button className="Real-check-obj" >DMP 보기</button>*/}
                            </div>
                            <div>
                                <span className="tast-des">* 등록된 DMP를 선택하면 DMP 정보가 자동으로 입력됩니다.</span>
                            </div>
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="dataCreate-container">
                        <div className="dataCreate-title">
                            <span>등록 대상 데이터</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <div className="dmpDataSets-container">
                                { showImportData && <ImportDataSet DtaSetNo={registerdataSets?.dmpMain?.DtaSetNo} /> }
                                {
                                    DtaSetHisNo && (
                                        <>
                                            <div className="dataSets-Inputboxs">
                                                <button className="dataSet-bring" onClick={toggleImportDataSets}>DMP 데이터세트 불러오기</button>
                                                <div className="selectbox-container">
                                                    <div className="DataPage-selectbox-check">
                                                        <select
                                                            className="createDataPage"
                                                            value={callImgData.summary?.DtaSetNm || ''}
                                                            readonly
                                                        >
                                                            <option value=''>-- 구분 --</option>
                                                            <option value="시료데이터">시료데이터</option>
                                                            <option value="방법데이터">방법데이터</option>
                                                            <option value="결과데이터">결과데이터</option>
                                                        </select>
                                                        <select
                                                            className="createDataPage"
                                                            id="selectBox"
                                                            value={callImgData.summary?.DtaSetNmSub || ''}
                                                            readonly
                                                        >
                                                            <option value=''>-- 선택 * 필수 --</option>
                                                            <option value="Food, Unprocessed">Food, Unprocessed</option>
                                                            <option value="Food, Processed">Food, Processed</option>
                                                            <option value="Food, Derived">Food, Derived</option>
                                                            <option value="Animal">Animal</option>
                                                            <option value="Human">Human</option>
                                                            <option value="Invertebrate">Invertebrate</option>
                                                            <option value="Microbe">Microbe</option>
                                                            <option value="Cell, Animal">Cell, Animal</option>
                                                            <option value="Cell, Human">Cell, Human</option>
                                                            <option value="Cell, Plant">Cell, Plant</option>
                                                            <option value="etc">etc</option>
                                                            {/* option 태그와 value 값을 넣어서 추가 하시면 됩니다.   */}
                                                        </select>

                                                        <input
                                                            type="text"
                                                            className="edit--box"
                                                            id="data-type-name"
                                                            name="DtaNm"
                                                            placeholder="데이터 타입명"
                                                            value={callImgData.summary?.DtaNm || ''}
                                                            readonly
                                                        />
                                                        <input type="text"
                                                               className="edit--box"
                                                               id="data-type-desc"
                                                               name="DtaDs"
                                                               placeholder="데이터 타입 설명"
                                                               value={callImgData.summary?.DtaDs || ''}
                                                               readonly
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="datePage-targetData-des">
                                                <span className="tast-des">* 등록된 DMP 데이터세트를 선택하면 데이터세트가 자동으로 입력됩니다.</span>
                                                <span className="tast-des">* TYPE은 문자열:"STRING", 숫자:"NUMERIC", 날짜:"DATE", 파일명:"FILE" 로 사용을 권장합니다.</span>
                                                <ToastGrid MethodData={callImgData.summary?.DtaSetNm} DtaSetNo = {callImgData.summary?.DtaSetHisNo} HisData={callImgData.summary?.DtaSetHisNo} ModifySets={callImgData.data} viewParams = {viewParams}/>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="start_line"></div>
                </section>

                <div className="RealData-save-btn-container">
                    <button id="dmp_list" onClick={() => hisListBtn()}>
                        목록
                    </button>
                </div>
            </div>
        </>
    )
}

export default DmpHisDataView
