import React, { useEffect, useState, useRef } from 'react';
import '../dmpCreate/dmpcreate.css';
import DataManagementGrid from "../../dmpDataManagement/dataManagementGrid";
import LastDmpShow from "../../lastDmp/lastDmpShow";
import DataPublicGrid from "../../dmpDataPublic/dataPublicGrid";
import TaskCheck from "../../taskCheck/taskCheck";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import DmpDataProductionPlan from "../../dmpDataProductionPlan/dmpDataProductionPlan";
import DmpModifyToastGrid from "../../dmpModify/dmpModifyToast";
import {
    useNewDmpManagementPlan,
    useNewDmpProductionPlan,
    useNewDmpPublicPlan,
    useNewDmpToastData
} from "../../stores/newWriteDmp";
import api from "../../api";
import {useTaskDataStore} from "../../stores/lastDmpData";
function DmpHisPageView() {

    const location = useLocation();
    const { DmpHisNo } = location.state || {}; // 전달된 id 가져오기

    // const { DmpHisNo } = useParams();


    const navigate = useNavigate();
    const dataRefs = useRef([]); // 동적으로 ref를 저장할 배열

    const [allData, setAllData] = useState([]);
    // 로딩 상태와 데이터 상태 관리
    const [allDmpData, setAllDmpData] = useState(null); // null로 초기화하여 데이터가 없는 상태를 구분

    const [updateProNo, setUpdateProNo] = useState("");
    const [updateProName, setUpdateProName] = useState("");
    const [updateProLeader, setUpdateProLeader] = useState("");
    const [modifyMethodSummary, setModifyMethodSummary] = useState("");
    const [modifyMethodAnalysis, setModifyMethodAnalysis] = useState("");

    const [showLastDmp, setShowLastDmp] = useState(false);
    const [showObjCheck, setShowObjCheck] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [modifySummaryData, setModifySummaryData] = useState([]);
    const [imageSrc, setImageSrc] = useState(`${process.env.PUBLIC_URL}/bg_file_select.png`);

    const [isProductionLoading, setIsProductionLoading] = useState(false);

    //생산 계획 값 저장 modify에서 사용 !!
    // const {newProductionPlandataSets} = useNewDmpProductionPlan();
    //관리 계획 값 저장 new!!
    //const { newManagementPlandataSets } = useNewDmpManagementPlan();
    //공개계획 값 저장
    //const { newPublicPlandataSets } = useNewDmpPublicPlan();

    // 가져온 데이터 setData 입니다.
    const dataTypes = ["시료데이터","방법데이터","결과데이터"];

    const lastSummaryItem = modifySummaryData[modifySummaryData.length - 1]?.Summary;
    const lastDataType = lastSummaryItem?.DtaSetNm;
    const currentTypeIndex = dataTypes.indexOf(lastDataType);
    const nextDataTypeIndex = (currentTypeIndex + 1) % dataTypes.length;
    const nextDataType = dataTypes[nextDataTypeIndex];




    //사이드 바 클릭 시 이동
    const taskInfoRef = useRef(null);
    const researchMethodRef = useRef(null);
    const researchImgRef = useRef(null);
    const sidebarDataSetsRef = useRef(null);
    const productionDataRef = useRef(null);
    // const analySideDataRef = useRef(null);
    const mgDataRef = useRef(null);
    const openDataRef = useRef(null);


    const handleScrollToSection = (ref) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth',  block: 'center'});
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`/api/history/detail?DmpHisNo=${DmpHisNo}`, {
                    withCredentials: true,
                });
                setAllDmpData(response.data);
            } catch (err) {
                console.error("요청에 실패했습니다.", err);
            }
        };

        fetchData();
    }, [DmpHisNo]); // id가 바뀔 때마다 데이터를 새로 불러오도록 설정


    useEffect(() => {
        if (allDmpData?.DmpMain) {
            setUpdateProNo(allDmpData.DmpMain?.ProjNo || '');
            setUpdateProName(allDmpData.DmpMain?.ProjNm || '');
            setUpdateProLeader(allDmpData.DmpMain?.RspnEmpNm || '');
            setModifyMethodSummary(allDmpData.DmpMain?.RsrcSmm || '');
            setModifyMethodAnalysis(allDmpData.DmpMain?.RsrcAnlyPlan || '');
            setModifySummaryData(allDmpData?.SumData || []); // 서머리 데이터 설정
        }

    }, [allDmpData]);

    const hisYearData = allDmpData?.Prod?.map((item) => item.PrdYear); // 연도 추출
    const uniqueYears = [...new Set(hisYearData)]; // 중복 제거

    const objHandler = () => {
        alert("이력페이지에서는 과제선택이 불가합니다.")
    }

    // 파일 업로드 처리
    // const handleImageChange = (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImageSrc(reader.result);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };

    // 추가 데이터 처리
    // const handleAddDataSet = () => {
    //     const newDataSets = dataTypes.map(type => ({
    //         DataMain: [
    //         ],
    //         Summary: {
    //             id: Date.now() + Math.random(), // 고유한 ID 생성
    //             DtaSetNm: type,
    //             DmpNo: allDmpData.DmpMain.DmpNo,
    //             DtaSetNmSub: "",
    //             DtaNm: "",
    //             DtaDs: "",
    //             DmpFlg: "Y",
    //         },
    //     }));
    //
    //     setModifySummaryData(prevData => [...prevData, ...newDataSets]);
    // };


    // 요약 데이터 업데이트 처리
    const handleUpdateChange = (id, event) => {
        const newValue = event.target.value;

        setModifySummaryData(prevData =>
            prevData.map(dataSet => {
                if (dataSet.Summary.DtaSetNo === id || dataSet.Summary.id === id) {
                    // 조건에 맞는 데이터셋만 업데이트
                    return {
                        ...dataSet,
                        Summary: {
                            ...dataSet.Summary,
                            DtaSetNm: newValue
                        }
                    };
                }
                // 나머지 데이터셋은 변경하지 않고 그대로 반환
                return dataSet;
            })
        );
    };


    const handleRequiredChange = (id, event) => {
        const newValue = event.target.value;
        setModifySummaryData(prevData =>
            prevData.map(dataSet => {
                if (dataSet.Summary.DtaSetNo === id || dataSet.Summary.id === id) {
                    return {
                        ...dataSet,
                        Summary: {
                            ...dataSet.Summary,
                            DtaSetNmSub: newValue, // DtaSetNmSub을 업데이트
                        }
                    }
                }
                // 나머지 데이터셋은 변경하지 않고 그대로 반환
                return dataSet;
            })
        );
    };

    const handleInputChange = (id, event) => {
        const newValue = event.target.value;
        setModifySummaryData(prevData =>
            prevData.map(dataSet => {
                if (dataSet.Summary.DtaSetNo === id || dataSet.Summary.id === id) {
                    return {
                        ...dataSet,
                        Summary: {
                            ...dataSet.Summary,
                            DtaNm: newValue, // DtaNm을 업데이트
                        },
                    }
                }
                return dataSet;
            })
        );
    };

    const handleInputdescChange = (id, event) => {
        const newValue = event.target.value;
        setModifySummaryData(prevData =>
            prevData.map(dataSet => {
                if (dataSet.Summary.DtaSetNo === id || dataSet.Summary.id === id) {
                    return {
                        ...dataSet,
                        Summary: {
                            ...dataSet.Summary,
                            DtaDs: newValue, // DtaDs을 업데이트
                        },
                    }
                }
                return  dataSet;
            })
        );
    };

    // 삭제 처리
    const handleDeleteDataSet = (id) => {
        setModifySummaryData(prevData =>
            prevData.filter(dataSet => dataSet.Summary.DtaSetNo !== id && dataSet.Summary.id !== id)
        );
    };

    const hisListHandler = () => {
        navigate(-1);  // 이전 페이지로 이동
    }

    useEffect(() => {
        // newDataSummary 배열이 변경될 때마다 ref 배열을 동기화
        dataRefs.current = dataRefs.current.slice(0, modifySummaryData.length);
    }, [modifySummaryData]);

    const handleScrollToElement = (index) => {
        if (dataRefs.current[index]) {
            dataRefs.current[index].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    return(
        <>
            <div className="dmp-banner-container">
                <div className="dmp-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/sub-introimg02.jpg`} className="dmp-img02" alt="search Logo" />
                    <div className="banner-text">
                        <span className="intro-subName">데이터 관리 계획</span>
                    </div>
                    <div className="dmp-description">
                        <div className="dmp-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 관리 계획 > 데이터 관리 계획 등록</span>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="side-container">
                        {/* 사이드바 */}
                        <div className="side-sidebar">
                            <span className="side-name">작성 단계</span>
                            <div className="side-hisBtn-container">
                            </div>
                            <div className="start-side_line"></div>
                            <ul className="side-menu-list">
                                <li>
                                    <button onClick={() => handleScrollToSection(taskInfoRef)} className="side-menu-button">
                                        과제 정보
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleScrollToSection(researchMethodRef)} className="side-menu-button">
                                        연구 방법 요약
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleScrollToSection(researchImgRef)} className="side-menu-button">
                                        연구데이터 구성
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleScrollToSection(sidebarDataSetsRef)} className="side-menu-button">
                                        데이터세트
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                {modifySummaryData.map((data, index) => (
                                    <li key={index}>
                                        <button className="side-datasets-button"
                                                onClick={() => handleScrollToElement(index)} // 클릭 시 스크롤 이동
                                        >
                                            {`- (${data.Summary.DtaSetNm})`}<br/>
                                            {`${data.Summary.DtaNm}`}
                                        </button>
                                    </li>
                                ))}
                                <li>
                                    <button onClick={() => handleScrollToSection(productionDataRef)} className="side-menu-button">
                                        생산 계획
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                {/*<li>*/}
                                {/*    <button onClick={() => handleScrollToSection(analySideDataRef)} className="side-menu-button">*/}
                                {/*        분석 계획*/}
                                {/*        <span className="required_color">*</span>*/}
                                {/*    </button>*/}
                                {/*</li>*/}
                                <li>
                                    <button onClick={() => handleScrollToSection(openDataRef)} className="side-menu-button">
                                        공개 계획
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                <li>
                                    <button onClick={() => handleScrollToSection(mgDataRef)} className="side-menu-button">
                                        관리 계획
                                        <span className="required_color">*</span>
                                    </button>
                                </li>
                                {/* 다른 항목도 동일하게 추가할 수 있음 */}
                            </ul>
                        </div>
                    </div>
                    <section className="contents-section">
                        <div className="management-plan-title">
                            <h1>데이터 관리 계획</h1>
                            <div>
                                <span className="required_color">*</span>
                                <span>필수 항목</span>
                            </div>
                        </div>
                        <div className="under_line"></div>
                        <div className="object-info" ref={taskInfoRef}>
                            <div className="object-info-title">
                                <span className="info">과제 정보</span>
                                <span className="required_color">*</span>
                            </div>
                            <div className="task-title">
                                <div className="task-wrapper">

                                    <>
                                        <button className="check-obj" onClick={objHandler}>과제 선택</button>
                                        {showObjCheck && <TaskCheck />}
                                    </>

                                    {
                                        allDmpData?.DmpMain?.DmpNo && (
                                            <div key={allDmpData.DmpMain.DmpNo} style={{display : 'flex'}}>
                                                <input
                                                    type="text"
                                                    className="edit--box"
                                                    value={updateProNo || ''}
                                                    readOnly
                                                    onChange={(e) => setUpdateProNo(e.target.value)}
                                                    placeholder="과제번호"
                                                />
                                                <input
                                                    type="text"
                                                    className="edit--box"
                                                    value={updateProName || ''}
                                                    readOnly
                                                    onChange={(e) => setUpdateProName(e.target.value)}
                                                    placeholder="과제명"
                                                />
                                                <input
                                                    type="text"
                                                    className="edit--box"
                                                    readOnly
                                                    value={updateProLeader || ''}
                                                    onChange={(e) => setUpdateProLeader(e.target.value)}
                                                    placeholder="연구책임자"
                                                />
                                            </div>
                                        )}
                                </div>
                                <div>
                                    <span>* 과제 번호를 선택하면 과제 정보가 자동으로 입력됩니다.</span>
                                </div>
                            </div>
                        </div>
                        <div className="start_line"></div>
                        <div className="research-method-info" ref={researchMethodRef}>
                            <div className="object-info-title">
                                <span className="info">연구 방법 요약</span>
                                <span className="required_color">*</span>
                            </div>
                            <div className="research-method-title">
                                <textarea
                                    type="text"
                                    value={modifyMethodSummary}
                                    readOnly
                                    onChange={(e) => setModifyMethodSummary(e.target.value)}
                                    className="edit--box"
                                    placeholder="연구방법을 서술형으로 작성하세요."
                                />
                            </div>
                        </div>
                        <div className="start_line"></div>
                        <div className="configuring-research-data-info" ref={researchImgRef}>
                            <div className="object-info-title">
                                <span className="info">연구데이터 구성</span>
                            </div>
                            <div className="research-method-title">
                                <label htmlFor="fileDiagram" style={{ cursor: 'pointer', display: 'block', textAlign: 'center' }}>
                                    {imageSrc ? (
                                        <img id="imgDiagram" src={imageSrc} alt="Diagram" style={{ maxWidth: '100%', height: 'auto' }} />
                                    ) : (
                                        <div>
                                            <img
                                                id="imgPlaceholder"
                                                src={`${process.env.PUBLIC_URL}/no-img02.png`}
                                                alt="No Image Available"
                                                style={{ maxWidth: '600px', height: 'auto', display: 'block', margin: '0 auto' }}
                                            />
                                            <p style={{ color: '#999', fontSize: '14px', marginTop: '10px' }}>이미지가 없습니다. 클릭해서 이미지를 첨부해주세요.</p>
                                        </div>
                                    )}
                                </label>

                            </div>
                        </div>
                        <div className="start_line"></div>

                        {
                            <div className="data-set-container" >
                                {modifySummaryData.map((updateSummaryItem, index) => (
                                    <div className="data-set-info" key={index}>
                                        <div className="data-set-title-wrapper">
                                            <div className="data-set-title"
                                                 ref={(el) => {
                                                     dataRefs.current[index] = el; // 동적으로 ref 배열에 항목 추가
                                                 }}
                                            >
                                                <span className="info">데이터 세트</span>
                                                <span className="required_color">*</span>
                                                {/*<button className="data-set-delete" onClick={() => handleDeleteDataSet(updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo)}>*/}
                                                {/*    <span>-</span>*/}
                                                {/*    <span>삭제</span>*/}
                                                {/*</button>*/}
                                            </div>
                                            <div className="selectbox-container">
                                                <div className="selectbox-check">
                                                    <select
                                                        className="resultData"
                                                        disabled
                                                        // id={`selectBox-${updateSummaryItem.Summary.DtaSetNo}`}
                                                        value={updateSummaryItem.Summary.DtaSetNm}
                                                        onChange={(event) => handleUpdateChange(updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo, event)}

                                                    >
                                                        <option value=''>-- 구분 --</option>
                                                        <option value="시료데이터">시료데이터</option>
                                                        <option value="방법데이터">방법데이터</option>
                                                        <option value="결과데이터">결과데이터</option>
                                                    </select>
                                                    <select
                                                        className="resultData"
                                                        id="selectBox"
                                                        disabled
                                                        value={updateSummaryItem.Summary.DtaSetNmSub}
                                                        onChange={(event) => handleRequiredChange( updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo, event)}
                                                    >
                                                        <option value=''>-- 선택 * 필수 --</option>
                                                        <option value="Food, Unprocessed">Food, Unprocessed</option>
                                                        <option value="Food, Processed">Food, Processed</option>
                                                        <option value="Food, Derived">Food, Derived</option>
                                                        <option value="Animal">Animal</option>
                                                        <option value="Human">Human</option>
                                                        <option value="Invertebrate">Invertebrate</option>
                                                        <option value="Microbe">Microbe</option>
                                                        <option value="Cell, Animal">Cell, Animal</option>
                                                        <option value="Cell, Human">Cell, Human</option>
                                                        <option value="Cell, Plant">Cell, Plant</option>
                                                        <option value="etc">etc</option>
                                                        {/* option 태그와 value 값을 넣어서 추가 하시면 됩니다.   */}
                                                    </select>

                                                    <input
                                                        type="text"
                                                        className="edit--box"
                                                        readOnly
                                                        value={updateSummaryItem.Summary.DtaNm}
                                                        onChange={(event) => handleInputChange( updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo, event)}
                                                        id="data-type-name"
                                                        name="DtaNm"
                                                        placeholder="데이터 타입명"
                                                    />
                                                    <input type="text"
                                                           className="edit--box"
                                                           readOnly
                                                           value={updateSummaryItem.Summary.DtaDs}
                                                           onChange={(event) => handleInputdescChange( updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo, event)}
                                                           id="data-type-desc"
                                                           name="DtaDs"
                                                           placeholder="데이터 타입 설명"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="toastui-wrapper">
                                            <DmpModifyToastGrid MethodData={updateSummaryItem.Summary.DtaSetNm} DateSets={updateSummaryItem} DmpDataSetsHisNo = {allDmpData.DmpMain.DmpHisNo} />
                                        </div>
                                    </div>
                                ))}
                                {/*<button className="data-set-add" type="button" onClick={handleAddDataSet}>{`+ ${nextDataType} 세트 양식 추가`}</button>*/}
                            </div>

                        }
                        <div className="start_line"></div>
                        {
                            uniqueYears.map((year, index) => (
                                <div className="data-hisTitle-info" key={index} ref={productionDataRef}>
                                    <div className="object-info-title">
                                        <span className="info"> {year}년 생산 계획</span>
                                        <span className="required_color">*</span>
                                    </div>
                                    {
                                        allDmpData && (
                                            <div>
                                                <DmpDataProductionPlan
                                                    updateProductionPn = {allDmpData}
                                                    setProductionLoading = {setIsProductionLoading}
                                                    year={year}
                                                    setAllData={setAllData}
                                                    DmpMgmtHisNo = {allDmpData.DmpMain.DmpHisNo}/>
                                            </div>
                                        )
                                    }
                                </div>
                            ))}
                        {/*<div className="start_line"></div>*/}
                        {/*<div className="data-analysis-info">*/}
                        {/*    <div className="object-info-title">*/}
                        {/*        <span className="info">연구데이터 분석 계획</span>*/}
                        {/*        <span className="required_color">*</span>*/}
                        {/*    </div>*/}
                        {/*    {*/}
                        {/*        allDmpData && (*/}
                        {/*            <div className="research-method-title">*/}
                        {/*            <textarea type="text"*/}
                        {/*                      className="edit--box"*/}
                        {/*                      readOnly*/}
                        {/*                      value={modifyMethodAnalysis}*/}
                        {/*                      onChange={(e) => setModifyMethodAnalysis(e.target.value)}*/}
                        {/*                      placeholder="연구데이터 분석 계획을 서술형으로 작성하세요."></textarea>*/}
                        {/*            </div>*/}
                        {/*        )*/}
                        {/*    }*/}
                        {/*</div>*/}
                        <div className="start_line"></div>

                        <div className="data-hispublic-info" ref={openDataRef}>
                            <div className="object-info-title">
                                <span className="info">공개 계획</span>
                                <span className="required_color">*</span>
                            </div>

                            {
                                allDmpData && (
                                    <div>
                                        <DataPublicGrid updateProductionPn = {allDmpData} DmpPublicHisNo = {allDmpData.DmpMain.DmpHisNo}/>
                                    </div>
                                )
                            }

                        </div>


                        <div className="start_line"></div>
                        <div className="data-hisTitle-info" ref={mgDataRef}>
                            <div className="object-info-title">
                                <span className="info">관리 계획</span>
                                <span className="required_color">*</span>
                            </div>

                            {
                                allDmpData && (
                                    <div>
                                        <DataManagementGrid updateProductionPn = {allDmpData} DmpProductionHisNo = {allDmpData.DmpMain.DmpHisNo}  />
                                    </div>
                                )
                            }
                        </div>
                        <div className="start_line"></div>
                    </section>
                </div>

                <div className="save-container">
                    {/*<button id="temporary-Storage">임시저장</button>*/}

                    <button id="dmp_list" onClick={hisListHandler}>
                        목록
                    </button>
                </div>

            </div>
        </>
    )
}
export default DmpHisPageView;