import React, { useEffect, useState } from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import "../submitStatus/dataSubmitStatus.css";
import api from "../../api";

function ProdDetail() {
    const { DmpNo } = useParams();
    const [prodData, setProdData] = useState({});


    const location = useLocation();
    const { ProjNm } = location.state || {}; // 전달된 id 가져오기


    const navigate = useNavigate();


    const [test, setTest] = useState([]);

    const userDetailPages = async () => {
        const endPoint = `/api/m/prod?DmpNo=${DmpNo}`;
        try {
            const res = await api.get(endPoint, {
                withCredentials: true,
            });
            setProdData(res.data.prod); // prod 데이터를 배열 형태로 설정

            setTest(res.data.file);
        } catch (error) {
            console.error("요청에 실패했습니다.", error);
        }
    };

    useEffect(() => {
        userDetailPages();
    }, [DmpNo]);


    const adminMenu = [
        { 'id': 1, 'name': "공개시기 관리(캘린더)", 'path': "/adminPage" },
        { 'id': 2, 'name': "과제 목록", 'path': "/adminPage/objView" },
        { 'id': 3, 'name': "데이터 제출 현황", 'path': "/adminPage/submintStatus" },
        { 'id': 4, 'name': "데이터 제출 목록 일괄", 'path': "/adminPage/dataSubmitList" },
        { 'id': 5, 'name': "데이터 제출 상태 변경", 'path': "/adminPage/dataSubmitStatusChange" },
        { 'id': 6, 'name': "DMP 작성 현황 및 제출 상태 변경", 'path': "/adminPage/dmpSubmitStatus" },
        { 'id': 7, 'name': "데이터 관리 계획 접근권한(User별)", 'path': "/adminPage/userManagement" },
        { 'id': 8, 'name': "데이터 관리 계획 접근권한(과제별)", 'path': "/adminPage/dmpAccessCheck" },
        { 'id': 9, 'name': "생산계획 누락현황 확인", 'path': "/adminPage/productionPlanMng" },
        { 'id': 10, 'name': "신청서 관리 페이지(신청서 정보)", 'path': "/adminPage/applicationManagement" },
    ];

    const years = prodData.length > 0 ? Object.keys(prodData[0]).filter((key) => key.includes("차 년도")) : [];

    const fileHandlerClick = (DtaSbmSeqNo, ProjNm, DtaNm) =>{
        navigate(`/adminPage/productionPlanMng/prodFiledown/${DtaSbmSeqNo}`, {
            state: {ProjNm, DtaNm}
        });
    }
    const activeMenuId = adminMenu.find((menu) => menu.path === location.pathname)?.id || 9;


    return (
        <div className="adminpage-container">
            <div className="small-title">
                <img src={`${process.env.PUBLIC_URL}/adminpage.png`} className="adminlist-img02" alt="search Logo" />
                <div className="adminhd-line"></div>
                <h2 className="menu-name">관리자 메뉴</h2>
            </div>
            <div className="menu-list">
                <div className="menu-title">
                    {adminMenu.map((item) => (
                        <React.Fragment key={item.id}>
                            <div className={`menu-item ${activeMenuId === item.id ? "active" : ""}`}>
                                <Link to={item.path}>
                                    {item.name}
                                </Link>
                            </div>
                            <div className="admin-under"></div>
                        </React.Fragment>
                    ))}
                </div>
                <div className="admin-line"></div>
                <div className="year-name">
                    <div className="submin-container">
                        <div className="proj-title">
                            <h3>년도 별 생산계획</h3>
                            <h4>({ProjNm})</h4>
                        </div>
                        {/* 년도별 생산계획 누락 데이터 테이블 */}
                        {prodData.length > 0 ? (
                            <table className="prod-table" >
                                <thead>
                                <tr>
                                    <th>DATATYPE_NAME</th>
                                    {years.map((year) => (
                                        <th key={year}>{year}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {prodData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.DtaNm}</td> {/* 데이터 타입 이름 */}
                                        {years.map((year) => (
                                            <td key={year}>{item[year] || 0}</td>
                                            ))}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        ) : (
                            <p className="noDataCss">데이터가 없습니다.</p>
                        )}
                    </div>
                    <div className="middle-line"></div>
                    <div>
                        <h3>제출건수</h3>
                        {test.length > 0 ? (
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                <tr className="dmplist-title">
                                    <th>DtaSbmSeqNo</th>
                                    <th>데이터명</th>
                                    <th>제출건수</th>
                                    <th>제출여부</th>
                                    <th>상세보기</th>
                                </tr>
                                </thead>
                                <tbody>
                                {test.map((row, index) => (
                                    <tr key={index} className="dmplist-Data">
                                        <td>{row.DtaSbmSeqNo || '-'}</td>
                                        <td>{row.DtaNm || '-'}</td>
                                        <td>{row.Cnt || '-'}</td>
                                        <td>{row.Status || '-'}</td>
                                        <td>
                                            <button
                                                className="dataStatus-management"
                                                onClick={() => fileHandlerClick(row.DtaSbmSeqNo, ProjNm, row.DtaNm)}
                                            >
                                                파일 상세보기
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        ) : (
                            <p className="noDataCss">데이터가 없습니다.</p>
                        )}
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ProdDetail;
