import './applicationPage.css'
import React, {useState, useEffect} from "react";
import api from "../api";
import Cookies from 'js-cookie';

function ViewData({selectedProjects, AppValue, myPagecancelHandler}) {
    const [currentDate, setCurrentDate] = useState("");
    const [topic, setTopic] = useState(""); // 성명
    // const [email, setEmail] = useState(""); // 이메일

    const [purpose, setPurpose] = useState(""); // 이용 목적
    const [usagePlan, setUsagePlan] = useState(""); // 이용 계획

    const [openFiles, setOpenFiles] = useState([]);

    const [openSendImgFile, setOpenSendImgFile] = useState([]);


    const [startyear, setStartYear] = useState("");  // 년도 상태
    const [startmonth, setStartMonth] = useState("");  // 월 상태
    const [startday, setStartDay] = useState("");  // 일 상태

    const [lastyear, setLastYear] = useState("");  // 년도 상태
    const [lastmonth, setLastMonth] = useState("");  // 월 상태
    const [lastday, setLastDay] = useState("");  // 일 상태


    const [userNo, setUserNo] = useState({});

    //마이페이지에서 데이터 보기
    const [myPageData ,setMyPageData] = useState({});
    const [myPagePurpose, setMyPagePurpose] = useState(''); // 선택된 목적의 상태 관리

    // // 컴포넌트가 렌더링될 때 현재 날짜 설정
    // useEffect(() => {
    //     if (AppValue) {
    //         api.get(`/api/my/appinfo?AppNo=${AppValue}`, { withCredentials: true })
    //             .then((res) => {
    //                 setMyPageData(res.data);
    //                 setMyPagePurpose(res.data.appInfo?.Purpose);
    //             });
    //     } else {
    //         const today = new Date();
    //         const year = today.getFullYear();
    //         const month = String(today.getMonth() + 1).padStart(2, "0");
    //         const day = String(today.getDate()).padStart(2, "0");
    //         setCurrentDate(`${year}-${month}-${day}`);
    //         api.get(`/api/auth/user`, { withCredentials: true }).then((res) => setUserNo(res.data));
    //     }
    // }, [AppValue]); // AppValue가 변경될 때만 실행되도록 의존성 배열 설정

    useEffect(() => {
        if (AppValue) {
            api.get(`/api/my/appinfo?AppNo=${AppValue}`, { withCredentials: true })
                .then((res) => {
                    setMyPageData(res.data);
                    setMyPagePurpose(res.data.appInfo?.Purpose);
                    setCurrentDate(res.data.appInfo.InsDt ? res.data.appInfo.InsDt.split(' ')[0] : '');

                });
        } else {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, "0");
            const day = String(today.getDate()).padStart(2, "0");
            setCurrentDate(`${year}-${month}-${day}`);

            api.get(`/api/auth/user`, { withCredentials: true }).then((res) => {
                // 상태를 업데이트하기 전에 현재 상태와 비교
                if (res.data !== userNo) {
                    setUserNo(res.data);
                }
            });
        }
    }, [AppValue]);


    useEffect(() => {
        if (myPageData?.appInfo?.StartDt) {
            const startDate = myPageData.appInfo.StartDt.split('-'); // "YYYY-MM-DD" 형식 분리
            setStartYear(startDate[0]);
            setStartMonth(startDate[1]);
            setStartDay(startDate[2]);
        }
        if (myPageData?.appInfo?.EndDt) {
            const endDate = myPageData.appInfo.EndDt.split('-'); // "YYYY-MM-DD" 형식 분리
            setLastYear(endDate[0]);
            setLastMonth(endDate[1]);
            setLastDay(endDate[2]);
        }
    }, [myPageData]);

    // 입력 값 변경 처리 함수들
    const handlePurposeChange = (event) => setPurpose(event.target.value);


    useEffect(() => {
        if (!AppValue) {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, "0");
            const day = String(today.getDate()).padStart(2, "0");

            setStartYear(year);
            setStartMonth(month);
            setStartDay(day);
        }
    }, [AppValue]);

    const handleYearChange = (event) => {
        const yearInput = event.target.value;

        if(yearInput.length  <= 4) {
            setStartYear(event.target.value);  // 입력된 연도를 상태로 저장
        }

    };

    //마지막 날짜
    const handlelastYearChange = (event) => {
        const startyearInput = event.target.value;

        if(startyearInput.length <= 4) {
            setLastYear(event.target.value);  // 입력된 연도를 상태로 저장
        }
    }

    const handlelastMonthChange = (event) => {
        const yearInput02 = event.target.value;

        if(yearInput02.length  <= 2) {
            setLastMonth(event.target.value);  // 입력된 월을 상태로 저장
        }
    }

    const handlelastDayChange = (event) => {
        const yearInput03 = event.target.value;

        if(yearInput03.length  <= 2) {
            setLastDay(event.target.value);  // 입력된 일을 상태로 저장
        }
    }
    const handleMonthChange = (event) => {
        const startMonthInput = event.target.value;

        if(startMonthInput.length <=2) {
            setStartMonth(event.target.value);  // 입력된 월을 상태로 저장
        }
    };

    const handleDayChange = (event) => {
        const startDay = event.target.value;

        if(startDay.length <=2) {
            setStartDay(event.target.value);  // 입력된 월을 상태로 저장
        }
    };

    const handleTopicChange = (e) => setTopic(e.target.value);

    // 서약서 신청
    const handleOpenDataFileChange = (e) => {
        // Flatten the file array
        setOpenFiles(Array.from(e.target.files));
    };

    const handleOpenUpload = async () => {
        if (openFiles.length === 0) {
            alert('서약서을 먼저 선택해주세요.');
            return;
        }
        const formData = new FormData();

        // 실제 파일 데이터를 FormData에 추가
        openFiles.forEach((file) => {
            formData.append('files', file);
        });
        formData.append('ct', "Terms");



        const csrfToken = Cookies.get('XSRF-TOKEN');

        try {
            const response = await api.post('/api/file/uploadfiles', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // 필요한 경우 추가 설정
                    'X-XSRF-TOKEN': csrfToken,
                },
                withCredentials: true,
            });
            alert('업로드 완료 되었습니다.');
            setOpenSendImgFile(response.data);

        } catch (error) {
            console.error('요청에 실패했습니다.', error);
            alert('파일이 업로드 되지 않았습니다.');
        }
    }


    const openPreviewHandler = async (fn, originName, dir, fileSeqNumber) => {

        try {
            // API 호출
            const response = await api.get(`/api/file/downloadFile`, {
                params: {
                    fn: fn,
                    originName: originName,
                    dir: dir,
                    FileSeqNo: fileSeqNumber
                },
                withCredentials: true,
                responseType: 'blob', // Blob으로 응답 받음
            });

            // 파일 다운로드 성공 처리
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', originName); // originName을 파일 이름으로 설정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // 다운로드 완료 후 링크 제거

            alert('다운로드에 성공 했습니다.');
        } catch (err) {
            console.error('요청에 실패했습니다.', err);
            alert('파일을 다운로드할 수 없습니다.');
        }
    };

    // const closeFile = (closeFile) => {
    //     setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexRemoveFile));
    //
    //     const removeUploadFile = setUploadData((prevFiles)=> prevFiles.filter((item, index) => {
    //         if (index === indexRemoveFile) {
    //             return (item.SaveFile);
    //         }
    //     }));
    //
    //     if(removeUploadFile !== undefined) {
    //         api.post(`/api/file/deleteFile`, {
    //             SaveFile : removeUploadFile,
    //         })
    //         alert("업로드 된 파일 삭제!!");
    //     }
    // }

    // 신청 버튼 클릭 시 동적으로 입력 데이터를 applicationData에 반영하여 API 요청

    return (
        <div className="application-wrapper">
            <div className="form-container">
                <h2>연구데이터 이용 신청서</h2>

                <div className="section">
                    <div className="checkbox">
                        <h3 htmlFor="applicantInfo">※신청자 정보</h3>
                        <p>이용 날짜: {currentDate}</p>
                    </div>

                    <table className="applicant-info-table" border="0">
                        <tbody>
                        <tr>
                            <td rowSpan="3" className="main-title">이용책임자</td>
                            <td  className="main-title">소속</td>
                            <td>
                                <input
                                    type="text"
                                    value={userNo?.userDept || myPageData?.appData?.[0]?.Department || ''}
                                />
                            </td>
                            <td  className="main-title">사번</td>
                            <td>
                                <input
                                    type="text"
                                    value={userNo?.userId || myPageData?.appData?.[0]?.UserNo || ''}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td  className="main-title">성명</td>
                            <td>
                                <input
                                    type="text"
                                    value={userNo?.userNm || myPageData?.appData?.[0]?.UserNm || ''}
                                />
                            </td>
                            <td  className="main-title">이메일</td>
                            <td>
                                <input
                                    type="text"
                                    value={userNo?.email || myPageData?.appData?.[0]?.Email || ''}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td  className="main-title">전화번호</td>
                            <td>
                                <input
                                    type="text"
                                    value={userNo?.userTel  || myPageData?.appData?.[0]?.PhoneNumber || ''}
                                />
                            </td>

                        </tr>

                        </tbody>
                    </table>

                    <div className="notice">
                        ※ 연구데이터 이용자 전원 "연구데이터 이용 서약서" 확인 및 제출 필수
                    </div>
                </div>



                <div className="section">
                    <div className="checkbox">
                        <h3 htmlFor="applicationDetails">※신청사항</h3>
                    </div>

                    <div className="application-title02">
                        <div className= "application-title02-cont">주제</div>
                        <input type="text"
                               style={{width : '100%'}} value={topic || myPageData?.appInfo?.Subject || ""}
                               onChange={handleTopicChange}
                               readOnly
                        />
                    </div>
                    <div className="application-underlien"></div>
                    <div className="purpose-title">
                        <div>이용목적</div>
                        <div>
                            <input
                                type="radio"
                                style={{ width: '20px' }}
                                name="purpose"
                                value="연구기획"
                                onChange={handlePurposeChange}
                                disabled={!(myPagePurpose === "연구기획" || purpose === '연구기획')}
                                checked={myPagePurpose === "연구기획" || purpose === '연구기획'}
                            /> 연구기획
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="purpose"
                                style={{ width: '20px' }}
                                value="정책개발"
                                onChange={handlePurposeChange}
                                disabled={!(myPagePurpose === "정책개발" || purpose === '정책개발')}
                                checked={myPagePurpose === "정책개발" || purpose === '정책개발'}
                            /> 정책개발
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="purpose"
                                style={{ width: '20px' }}
                                value="교육자료"
                                onChange={handlePurposeChange}
                                disabled={!(myPagePurpose === "교육자료" || purpose === '교육자료')}
                                checked={myPagePurpose === "교육자료" || purpose === '교육자료'}
                            /> 교육자료
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="purpose"
                                style={{ width: '20px' }}
                                value="학술연구"
                                onChange={handlePurposeChange}
                                disabled={!(myPagePurpose === "학술연구" || purpose === '학술연구')}
                                checked={myPagePurpose === "학술연구" || purpose === '학술연구'}
                            /> 학술연구
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="purpose"
                                style={{ width: '20px' }}
                                value="논문/출판"
                                onChange={handlePurposeChange}
                                disabled={!(myPagePurpose === "논문/출판" || purpose === '논문/출판')}
                                checked={myPagePurpose === "논문/출판" || purpose === '논문/출판'}
                            /> 논문/출판
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="purpose"
                                style={{ width: '20px' }}
                                value="기타"
                                onChange={handlePurposeChange}
                                disabled={!(myPagePurpose === "기타" || purpose === '기타')}
                                checked={myPagePurpose === "기타" || purpose === '기타'}
                            /> 기타
                        </div>
                    </div>

                    <div className="application-underlien"></div>

                    <div className="period-days ">
                        <div>이용기간</div>
                        <div className="periodView-dayes">
                            <input
                                type="text"
                                style={{ width: "50px", marginBottom: '0px',  marginLeft: '10px'  }}
                                value={startyear}
                                onChange={handleYearChange}
                                readOnly
                                placeholder="YYYY"
                            />
                            <label>년</label>
                            <input
                                type="text"
                                style={{ width: "40px", marginBottom: '0px' }}
                                value={startmonth}
                                onChange={handleMonthChange}
                                readOnly
                                placeholder="MM"
                            />
                            <label>월</label>
                            <input
                                type="text"
                                style={{ width: "40px", marginBottom: '0px' }}
                                value={startday}
                                onChange={handleDayChange}
                                readOnly
                                placeholder="DD"
                            />
                            <label>일</label>

                            ~
                            <input
                                type="text"
                                style={{ width: "50px", marginBottom: '0px',  marginLeft: '10px'  }}
                                value={lastyear}
                                onChange={handlelastYearChange}
                                readOnly
                                placeholder="YYYY"
                            />
                            <label>년</label>
                            <input
                                type="text"
                                style={{ width: "40px", marginBottom: '0px' }}
                                value={lastmonth}
                                onChange={handlelastMonthChange}
                                readOnly
                                placeholder="MM"
                            />
                            <label>월</label>
                            <input
                                type="text"
                                style={{ width: "40px", marginBottom: '0px' }}
                                value={lastday}
                                onChange={handlelastDayChange}
                                readOnly
                                placeholder="DD"
                            />
                            <label>일</label>

                        </div>
                    </div>
                    <div className="application-underlien"></div>

                    <tr>
                        <td rowSpan="4" className="main-title" style={{ textAlign: 'center', verticalAlign: 'middle' }}>신청데이터</td>
                        <td style={{ textAlign: 'center' }}>종류</td>
                        <td style={{ textAlign: 'center' }}>명칭</td>
                        <td style={{ textAlign: 'center' }}>과제명/연구책임자</td>
                    </tr>

                    {
                        (myPageData?.appData?.length > 0) ? (
                            <>
                                <tr>
                                    <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[0]?.DtaSetNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                    <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[0]?.DtaNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                    <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[0]?.ProjNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[1]?.DtaSetNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                    <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[1]?.DtaNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                    <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[1]?.ProjNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[2]?.DtaSetNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                    <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[2]?.DtaNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                    <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={myPageData.appData[2]?.ProjNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                </tr>
                                <div className="application-underlien"></div>
                                <div className="text-table">
                                    <div className='text-titl02'>공동이용자</div>
                                    <div className='textarea-wrapper'>
                                        <textarea style={{ width: "100%", height: "100px"}} value={myPageData.appInfo?.JointUsers} ></textarea>
                                    </div>
                                </div>
                                <div className="application-underlien"></div>

                                <div className="text-table">
                                    <div className='text-titl02'>신청데이터<br/>이용계획</div>
                                    <div className='textarea-wrapper'>
                                        <textarea style={{ width: "100%", height: "120px"}} value={myPageData.appInfo?.UsagePlan} ></textarea>
                                    </div>
                                </div>
                                <div className="application-underlien"></div>
                            </>
                        ) : (
                            selectedProjects?.length > 0 && (
                                <>
                                    <tr>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[0]?.DtaSetNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[0]?.DtaNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[0]?.ProjNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[1]?.DtaSetNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[1]?.DtaNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[1]?.ProjNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[2]?.DtaSetNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[2]?.DtaNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                        <td style={{ padding: '10px 12px', textAlign: 'center' }}><input type="text" value={selectedProjects[2]?.ProjNm || ''} style={{ width: "85%", marginBottom: '0px' }} /></td>
                                    </tr>
                                    <div className="application-underlien"></div>
                                    <div className="text-table">
                                        <div className='text-titl02'>공동이용자</div>
                                        <div className='textarea-wrapper'>
                                            <textarea
                                                style={{ width: "100%", height: "120px" }}
                                                readOnly
                                                value={myPageData.appInfo?.JointUsers || "내용이 없습니다."}
                                            />
                                        </div>
                                    </div>
                                    <div className="application-underlien"></div>
                                    <div className="text-table">
                                        <div className='text-titl02'>신청데이터<br/>이용계획</div>
                                        <div className='textarea-wrapper'>
                                            <textarea style={{ width: "100%", height: "120px"}} value={usagePlan} onChange={(event) => setUsagePlan(event.target.value)} ></textarea>
                                        </div>
                                    </div>
                                    <div className="application-underlien"></div>
                                </>
                            )
                        )
                    }
                    <br/>
                    <div className= "Openoath-title">
                        <div className="Openoath-send" >
                            <span>서약서 제출</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="application-view-container">
                            {
                                myPageData?.appFile && myPageData.appFile.length > 0 ? (
                                    myPageData.appFile.map((file, index) => (
                                        <div className="mypage-viewData" key={index}>
                                            <div className="fileName">
                                                <div>
                                                    <p>{file.OriginNm}</p> {/* 파일 이름 표시 */}
                                                </div>
                                            </div>
                                            <div className="RealData-btnContainer">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/btn_preview.png`}
                                                    alt="preview"
                                                    onClick={() => openPreviewHandler(file.SaveNm, file.OriginNm, file.FilePath, file.TermsNo)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    null
                                )
                            }

                            {/*<input*/}
                            {/*    type="file"*/}
                            {/*    id="openfileDiagram"*/}
                            {/*    name="dataSubmitFiles"*/}
                            {/*    multiple*/}
                            {/*    onChange={handleOpenDataFileChange}*/}
                            {/*    className="search-input"*/}
                            {/*    placeholder="파일 이름"*/}
                            {/*/>*/}

                            <div className="application-file-list">
                                {
                                    openFiles.map((file, index) => (
                                        <div className="fileName" key={index}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                                alt="close"
                                                style={{ cursor: 'pointer' }}
                                                // Implement file removal logic here if needed
                                            />
                                            <div>
                                                <p>{file?.name}</p> {/* 업로드된 파일 이름 표시 */}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <div className="application-btn">
                    {
                        myPageData?.appData?.length > 0 ? (
                            <>
                                <button onClick={myPagecancelHandler} className="application-cancel">
                                    닫기
                                </button>
                            </>


                        ) : (
                            <>
                                <button className="application-apply" >
                                    신청
                                </button>
                                <button className="application-cancel">
                                    취소
                                </button>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    );
}




export  default ViewData;
