import React, { useState, useEffect } from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import '../dmpManagement/dmpList/dmplist.css';
import api from '../api';

const BoardComponent = () => {
    const [boardValue, setBoardValue] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
    const [btnPage, setBtnPage] = useState(1);

    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
    const pageSize = 10; // 한 페이지에 표시할 데이터 개수
    const pageGroupSize = 10; // 한 그룹에 표시할 페이지 번호 개수 (1-10, 11-20 등)


    const [authCheck, setAuthCheck] = useState(null); // "Y" 또는 "N"
    const [error, setError] = useState(null); // 에러 메시지 저장

    const navigate = useNavigate();

    useEffect(() => {
        const checkAdminStatus = async () => {

            if (error && error.includes("세션이 유효하지 않습니다")) {
                return navigate("/login"); // 로그인 페이지로 리다이렉트;
            }

            try {
                const res = await api.get("/api/auth/ckeckadmin", {
                    withCredentials: true,
                });

                if (res.status === 200) {
                    setAuthCheck(res.data); // "Y"
                    // alert("관리자 권한이 확인되었습니다.");
                } else {
                    setAuthCheck("N");
                    // alert("일반 사용자로 확인되었습니다.");
                }
            } catch (error) {
                if (error.response) {
                    // 서버 응답이 있는 경우 (401, 403 등)
                    if (error.response.status === 401) {
                        setError("세션이 유효하지 않습니다. 다시 로그인해주세요.");
                        // alert("로그인이 필요합니다. 로그인 페이지로 이동합니다.");
                        // navigate("/login"); // 로그인 페이지로 리다이렉트
                    } else if (error.response.status === 403) {
                        // alert("관리자 권한이 없습니다.");
                    }
                    setAuthCheck("N"); // 관리자 아님
                } else {
                    // 서버 응답이 없는 경우 (네트워크 오류 등)
                    alert("서버와 통신 중 문제가 발생했습니다.");
                }
            }
        };

        checkAdminStatus();
    }, []);


    const fetchData = async ( page = 1) => {
        try {
            const start = (page - 1) * pageSize + 1; // 페이지 시작 위치 계산
            const res = await api.get(`/api/board/list?seq=${1}&ps=${start}&pl=${pageSize}`, {
                withCredentials: true
            });
            setBoardValue(res.data.list);
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산
        } catch (error) {
            console.error("요청에 실패했습니다.", error);
        }
    };



    // 페이지 변경 시 데이터를 다시 불러옴
    useEffect(() => {
        fetchData(currentPage); // 검색어와 현재 페이지를 기준으로 API 호출
    }, [currentPage]); // 페이지가 변경될 때만 호출


    const handerbtn = (pageNumber) => {
        setCurrentPage(pageNumber); // 상태 업데이트
        fetchData(pageNumber); // 최신 값을 사용하여 즉시 데이터 가져오기
    };

    // 페이지네이션 렌더링 함수

    const renderPagination = () => {
        const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => handerbtn(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages.map((page) => (
                    <button
                        key={page}
                        className={`pagination-button ${page === currentPage ? "active" : ""}`}
                        onClick={() => handerbtn(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => handerbtn(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    // Row 클릭 시 상세 페이지로 이동
    const handleRowClick = (BoardSeq, Seq) => {
        navigate(`/board/boardView?BoardSeq=${BoardSeq}&seq=${Seq}`);
    };
    // 새글 작성 페이지로 이동 (BoardSeq에 맞게)
    const newWriteHandler = () => {
        navigate(`/board/write/${1}`, {
            state: { fromBoardSeq: 1 }  // 현재 BoardSeq 값을 state로 전달
        });
    };

    return (
        <div className="dmpReallist-container">
            <div className="dmpReallist-banner-img">
                <img src={`${process.env.PUBLIC_URL}/sub-introimg01.jpg`} className="dmpReallist-img02" alt="search Logo" />
                <div className="banner-Realtext">
                    <span className="intro-subName">새로운 소식을 알려드립니다.</span>
                </div>
                <div className="dmpReallist-description">
                    <div className="dmpReallist-description02">
                        <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                        <span> > 소개 > 공지사항</span>
                    </div>
                </div>
            </div>

            <div className="dmpReallist-inputbox"></div>
                <div style={{ height: '100vh' }}>

                    <div className="board-input-box">
                        {authCheck === "Y" && (
                            <>
                                <img
                                    className="newimgCur"
                                    src={`${process.env.PUBLIC_URL}/btn_newwrite.png`}
                                    onClick={newWriteHandler}
                                    alt="new write"
                                />
                                {/*<p>관리자 권한이 확인되었습니다.</p>*/}
                            </>
                        )}

                        {authCheck === "N" && <></>} {/* 관리자가 아닐 경우 아무것도 렌더링하지 않음 */}
                        {/*{error && <p style={{ color: "red" }}>{error}</p>} /!* 에러 메시지만 별도로 렌더링 *!/*/}
                    </div>
                    <div className="dmpReallist-start_line"></div>

                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                        <tr className="dmpReallist-title">
                            <th>카테고리</th>
                            <th>제목</th>
                            <th>작성자</th>
                            <th>등록일</th>
                            <th>조회</th>
                        </tr>
                        </thead>
                        <tbody>
                        {boardValue?.map((row, index) => (
                            <tr className="dmpReallist-Data" key={index}>
                                <td>{row.BoardNm}</td>
                                <td style={{ cursor: 'pointer' }} onClick={() => handleRowClick(row.BoardSeq, row.BoardListSeq)}>{row.Title}</td>
                                <td>{row.UserNm}</td>
                                <td className="boards-page">{row.InsDt}</td>
                                <td>{row.ReadCnt}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {renderPagination()}
                </div>
        </div>
    );
};

export default BoardComponent;
