import React, {useEffect, useRef, useState} from "react";
import Grid from "@toast-ui/react-grid";
import '../toast/toastGrid.css';
import {useNewDmpManagementPlan} from "../stores/newWriteDmp";

function DataManagementGrid({updateProductionPn, DmpProductionHisNo ,proplanDataTypes}) {
    const gridRef = useRef(null);

    const { setNewManagementPlandataSets } = useNewDmpManagementPlan();

    const [mnCellValue, setMnCellValue] = useState([]);


    //연구데이터 관리 계획
    // const {setManagementDataSets} = useManagementDataStore()

    useEffect(() => {
        if(updateProductionPn && updateProductionPn.Mnmt) {
            const addList = updateProductionPn.Mnmt.map(item => ({
                    DmpMgmtNo:item.DmpMgmtNo,
                    DmpNo:item.DmpNo,
                    dataType:item.DtaNm,
                    method:item.MngMth,
                    DmpPrdNo:item.DmpPrdNo,
                    individualManagement:item.MngResn,
                    dataLocation:item.MngStgMth,
                    storagePeriod:item.MngOpnMth,
                    // periodExpires:item.MngOpnMth,
                    DelFlg:item.DelFlg,
                    InsDt: item.InsDt,
                    UpdDt: item.UpdDt,
                    delete:'삭제',
                }))
            setMnCellValue(addList);
        }
        else {
            setMnCellValue([]); // 또는 초기값을 설정
        }
    }, [updateProductionPn]);

    const columns = [
        {
            header: '데이터타입명',
            name: 'dataType',
            editor: DmpProductionHisNo ? false : {
                type: 'select',
                options: {
                    listItems: Array.isArray(proplanDataTypes)
                        ? proplanDataTypes.map(type =>
                            typeof type === 'object' && type.DtaNm
                                ? { text: type.DtaNm, value: type.DtaNm }
                                : { text: type, value: type }
                        )
                        : Array.isArray(proplanDataTypes?.Summary)
                            ? proplanDataTypes.Summary.map(type => ({
                                text: type.DtaNm || type,
                                value: type.DtaNm || type
                            }))
                            : []
                }
            },
            width: 250,
        },
        {
            header: '관리방법',
            name: 'method',
            editor: DmpProductionHisNo ? false : {
                type: 'select',
                options: {
                    listItems: [
                        { text: 'DMP 플랫폼', value: 'DMP 플랫폼' },
                        { text: '개별관리', value: '개별관리' },
                    ],
                },
            },
            width: 180
        },
        {
            header: '개별관리 사유',
            name: 'individualManagement',
            // editor를 함수로 전달하여 동적으로 편집 가능 여부를 설정
            editor: DmpProductionHisNo ? false : 'text',
            width: 140
        },
        {
            header: '데이터보관 방법',
            name: 'dataLocation',
            editor: DmpProductionHisNo ? false : 'text',
            width: 150
        },
        {
            header: '데이터공개방법',
            name: 'storagePeriod',
            editor: DmpProductionHisNo ? false : 'text',
            width: 150
        },
        // {
        //     header: '데이터공개시점',
        //     name: 'periodExpires',
        //     editor: DmpProductionHisNo ? false : 'text',
        //     width: 210
        // },
        {
            header: '행 삭제',
            name: 'delete',
            formatter: ({ row }) => DmpProductionHisNo
                ? `<span></span>`
                : `<button class="btn-delete" data-row-key="${row.rowKey}">Delete</button>`,
            width: 100,
        }
    ];

    const handleAddRow = () => {
        const newRow = {
            id: Date.now(), // Using current timestamp as a unique ID
            dataType:null,
            method:null,
            individualManagement:null,
            dataLocation:null,
            storagePeriod:null,
            // periodExpires:null,
        };
        setMnCellValue(prevList => [...prevList, newRow]);
    };

    // const handleDeleteRow = (rowKey) => {
    //     const parsedRowKey = parseInt(rowKey, 10);
    //     if (isNaN(parsedRowKey)) {
    //         console.error("Invalid rowKey:", rowKey);
    //         return;
    //     }
    //     setMnCellValue(prevList => prevList.filter(row => row.rowKey !== parsedRowKey));
    // };

    const handleDeleteRow = (rowKey) => {
        const parsedRowKey = parseInt(rowKey, 10);
        if (isNaN(parsedRowKey)) {
            return;
        }

        const resetRowKeys = (data) => data.map((row, index) => ({ ...row, rowKey: index }));

        const updatedList = resetRowKeys(mnCellValue.filter(row => row.rowKey !== parsedRowKey));

        setMnCellValue(updatedList);


        // 모든 행이 삭제되었을 때 빈 배열 반환
        if (updatedList.length === 0) {
            return [];
        }
    };



    useEffect((ev) => {
        const grid = gridRef.current.getInstance();


            if (!ev || !ev.changes || ev.changes.length === 0) return;

            ev.changes.forEach(({ rowKey, columnName, value }) => {
                // 값이 null, undefined 또는 빈 문자열일 경우 변경 중단
                if (value === undefined || value === null || value === '') {
                    alert(`"${columnName}" 값은 비워둘 수 없습니다.`);
                    ev.stop(); // 변경 중단
                }
            });


        if (grid) {
            mnCellValue.forEach((row, rowKey) => {
                if (row.method === 'DMP 플랫폼') {
                    // DMP 플랫폼인 경우 해당 셀을 비활성화
                    grid.disableCell(rowKey, 'individualManagement');
                    grid.disableCell(rowKey, 'dataLocation');
                    grid.disableCell(rowKey, 'storagePeriod');
                }
            });
        }

        if (!grid) return;


        grid.on('click', (ev) => {

            if (ev.targetType === 'emptySpace') {
                ev.stop();
                return;
            }

            if (ev.targetType === 'cell' && ev.nativeEvent.target.className.includes('btn-delete')) {
                const rowKey = ev.nativeEvent.target.getAttribute('data-row-key');
                handleDeleteRow(rowKey);
            }
        });

        grid.on('keydown', (ev) => {
            const focusedCell = grid.getFocusedCell();
            if (ev.targetType === 'emptySpace') {
                ev.stop();
                return;
            }
            // focusedCell이 없으면 이벤트 중단
            if (!focusedCell) {
                ev.stop(); // Grid 기본 동작 중단
                const nativeEvent = ev.nativeEvent;
                if (nativeEvent && typeof nativeEvent.preventDefault === 'function') {
                    nativeEvent.preventDefault();
                }
                return;
            }

            // editor가 없는 셀도 동작 중단
            if (!columns.find((col) => col.name === focusedCell.columnName)?.editor) {
                ev.stop();
                const nativeEvent = ev.nativeEvent;
                if (nativeEvent && typeof nativeEvent.preventDefault === 'function') {
                    nativeEvent.preventDefault();
                }
            }
        });

        // mnCellValue 배열이 빈 배열이라도 상태를 업데이트하도록 수정
        if (mnCellValue.length === 0) {
            setNewManagementPlandataSets([]);
        } else {
                setNewManagementPlandataSets(mnCellValue);
        }
    }, [mnCellValue, setNewManagementPlandataSets]);



    const handleGridUpdated = (ev) => {
        const gridInstance = gridRef.current?.getInstance();
        gridInstance.finishEditing(); // 값 확정 및 커밋

        if (ev && ev.changes && ev.changes.length > 0) {
            const gridInstance = gridRef.current?.getInstance();

            const updatedData = [...mnCellValue];

            ev.changes.forEach(({ rowKey, columnName, value }) => {

                // if (!updatedData[rowKey]) return;

                if (columnName === 'method') {
                    if (value === 'DMP 플랫폼') {
                        // 'DMP 플랫폼' 선택 시 컬럼 초기화 및 비활성화
                        updatedData[rowKey] = {
                            ...updatedData[rowKey],
                            method: 'DMP 플랫폼',
                            individualManagement: '',
                            dataLocation: '',
                            storagePeriod: '',
                        };

                        gridInstance.disableCell(rowKey, 'individualManagement');
                        gridInstance.disableCell(rowKey, 'dataLocation');
                        gridInstance.disableCell(rowKey, 'storagePeriod');
                    } else if (value === '개별관리') {

                        // 'DMP 플랫폼' 선택 시 컬럼 초기화 및 비활성화
                        updatedData[rowKey] = {
                            ...updatedData[rowKey],
                            method: '개별관리',

                        };
                        // '개별관리' 선택 시 컬럼 활성화
                        gridInstance.enableCell(rowKey, 'individualManagement');
                        gridInstance.enableCell(rowKey, 'dataLocation');
                        gridInstance.enableCell(rowKey, 'storagePeriod');
                    }
                }
                else {
                    // 정상적으로 값 업데이트
                    updatedData[rowKey] = { ...updatedData[rowKey], [columnName]: value };
                }
            });

            setMnCellValue(updatedData);
        }
    };


    return (
        <div className="toast-container">
            { (!DmpProductionHisNo) && (
                <div className="row-btn">
                    <button className="public-data-addrow" type="button" onClick={handleAddRow}>
                        행추가
                    </button>
                </div>
            )}

            <Grid
                data={mnCellValue}
                ref={gridRef}
                columns={columns}
                rowHeight={'auto'}
                bodyHeight={300}
                rowHeaders={['rowNum']}
                editable={true}  // 셀 편집 가능
                copyOptions={{ useFormattedValue: true }}  // 복사-붙여넣기 가능하게 설정
                onAfterChange = {handleGridUpdated}
            />
        </div>
    );
}

export default DataManagementGrid;
